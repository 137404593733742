<template>
  <div class="bpy-mobile-forget-warp">
    <template v-if="code_step == 1">
      <div class="title">
        验证手机号
        <div class="info-text">
          <span>为了您的账号安全 请先验证手机号</span>
        </div>
      </div>
      <div class="login-form" v-if="login_type == 1">
        <div class="input-box">
          <input v-model="form.account" maxlength="11" placeholder="请输入手机号" @input="mobileChange" @blur="validateMobile"/>
        </div>
        <div class="error-text" v-if="!account_check.status && account_check.msg">
          {{account_check.msg}}
        </div>
        <div class="submit-btn" :class="{active: can_next}" @click="goCode">
          验证手机号
        </div>
      </div>
    </template>
    <template v-if="code_step == 2">
      <div class="title">
        输入验证码
        <div class="info-text">
          已为 {{form.account}} 手机发送短信验证码
        </div>
      </div>
      <div class="login-form">
        <input ref="code" class="hide-code" type="text" maxlength="6" v-model="form.code" @input="codeChange" @keydown="codeDown" @compositionend="compositionend" @paste="paste" />
        <div class="code-box">
          <div class="show-code" :class="{active: code_index == i || (code_index == 6 && i == 5)}" v-for="(c, i) in code_arr" :key="i" @click="setIndex(i)">{{c}}</div>
          <!-- <input class="show-code" type="tel" maxlength="1" v-model="code_arr[i]" v-for="(c, i) in code_arr" :key="i" @input="codeChange($event, i)" @click="setIndex(i)"/> -->
        </div>
        <div class="code-info">
          <div class="error-text" v-if="!code_check.status && code_check.msg">
            {{code_check.msg}}
          </div>
          <div class="code-msg">
            <div class="send-btn" v-if="!code_status" @click="sendCode">
              重新发送
            </div>
            <div class="no-send-btn" v-else>
              {{send_code_text}}
            </div>
          </div>
        </div>
        <div class="submit-btn" :class="{active: can_code_login}" @click="codeLogin">
          <a-spin v-if="code_login_loading" />
          <span>下一步</span>
        </div>
        <div class="text-sub-btn" @click="goBackStep">
          <span>返回上一步</span>
        </div>
      </div>
    </template>
    <template v-if="code_step == 3">
      <div class="title">
        设置新密码
        <div class="info-text">
          请设置新的登录密码
        </div>
      </div>
      <div class="login-form">
        <div class="input-box">
          <input :type="is_hide ? 'password' : 'text'" v-model="form.password" placeholder="6-20 位字母或数字" @input="passwordChange"/>
          <img class="hide-icon" :src="hide_icon"  @click="is_hide = !is_hide" />
        </div>
        <!-- <div class="error-text" v-if="!password_check.status && password_check.msg && !password_check.errCode">
          {{password_check.msg}}
        </div> -->
        <div class="input-box">
          <input :type="is_hide2 ? 'password' : 'text'" v-model="form.confirm_password" placeholder="6-20 位字母或数字" @input="confirmPasswordChange"/>
          <img class="hide-icon" :src="hide_icon2"  @click="is_hide2 = !is_hide2" />
        </div>
        <div class="error-text" v-if="(!password_check.status && password_check.msg) || (!confirm_password_check.status && confirm_password_check.msg)">
          {{password_check.msg || confirm_password_check.msg}}
        </div>
        <div class="submit-btn" :class="{active: can_login}" @click="setPwdLogin">
          <a-spin v-if="login_loading" />
          <span>重置并登录</span>
        </div>
        <!-- <div class="text-sub-btn" @click="goBackStep">
          <span>返回上一步</span>
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
import {routes} from './index'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'MobileLogin',
  components: { },
  created () {
    document.title = '忘记密码 - 博普云'
  },
  data () {
    return {
      login_type: 1,
      form: {
        account: '',
        code: new Array(6).fill(' ').join(''),
        password: '',
        confirm_password: '',
        token_key: ''
      },
      account_check: {
        status: false,
        msg: ''
      },
      password_check: {
        status: false,
        msg: ''
      },
      confirm_password_check: {
        status: false,
        msg: ''
      },
      code_count: '',
      code_check: {
        status: false,
        msg: '',
      },
      code_step: 1,
      is_hide: true,
      is_hide2: true,
      code_index: 0,
      code_login_loading: false,
      login_loading: false
    }
  },
  computed: {
    hide_icon() {
      if (this.is_hide) return '/images/icon-input-invisible-normal.svg'
      return '/images/icon-input-visible-normal.svg'
    },
    hide_icon2() {
      if (this.is_hide2) return '/images/icon-input-invisible-normal.svg'
      return '/images/icon-input-visible-normal.svg'
    },
    can_code_login() {
      return this.code_arr.every(val=>{
        return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(val)
      })
    },
    can_login() {
      return this.password_check.status && this.confirm_password_check.status
    },
    can_next() {
      return this.account_check.status
    },
    can_send_code() {
      return !this.code_status && this.account_check.status
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}s后可重新发送`
      }
      return '重新发送'
    },
    code_arr:{
      get() {
        let arr = new Array(6).fill(' ')
        arr.forEach((val,i) => {
          arr[i] = this.form.code.substr(i, 1)
        })
        return arr
      },
      set(val) {
        this.form.code = val.join('')
      }
    }
  },
  watch: {
    // code_arr(code){
    //   let flag =  code.every(val=>{
    //     return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(val)
    //   })
    //   this.code_check = {
    //     status: flag,
    //     msg: ''
    //   }
    // }
  },
  methods: {
    goBackStep() {
      this.code_step = 1
      this.form.code = new Array(6).fill(' ').join('')
    },
    setIndex(i){
      this.code_index = i
      this.$refs.code.focus()
      this.$refs.code.setSelectionRange(i, i + 1)
    },
    // codeDown(e) {
    //   if (e.key == 'Backspace'|| e.keyCode == 8) {
    //     e.preventDefault()
    //     let code = [...this.code_arr]
    //     if (code[this.code_index] == ' ' || this.code_index > 5) {
    //       this.code_index--
    //     }
    //     code[this.code_index] = ' '
    //     this.form.code = code.join('')
    //     console.log(this.form.code)
    //     this.$nextTick(() => {
    //       if (this.code_index > 0) {
    //         this.setIndex(this.code_index - 1)
    //       } else {
    //         this.setIndex(0)
    //       }
    //     })
    //   } else if (![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(e.keyCode)) {
    //     e.preventDefault()
    //   }
    // },
    // codeChange(e) {
    //   // let data = this.code_arr[this.code_index]
    //   // if (this.code_index == 5 && ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(data)) return
    //   // if ((!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(data) && data != ' ')) {
    //   //   let code = [...this.code_arr]
    //   //   code[this.code_index] = '0'
    //   //   this.code_arr = code
    //   //   this.$nextTick(() => {
    //   //     this.setIndex(this.code_index)
    //   //   })
    //   // } else {
    //   // }
    //     if (this.code_index <5) {
    //       this.code_index++
    //     }
    //     this.setIndex(this.code_index)
    // },
        paste(e) {
      let number = ''
      let data = (e.clipboardData || window.clipboardData).getData('text')
      data.split('').forEach(val => {
        if(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(val)) {
          number += val
        }
      })
      let arr = new Array(6).fill(' ')
      let pre_str = this.code_copy.substr(this.code_index, this.code_copy.length - 1)
      let append_str = this.code_copy.substr(0, this.code_index)
      let full_str = (pre_str + number + append_str).replace(/ /g, '')
      arr.forEach((val, i) => {
        arr[i] = full_str.substr(i, 1)
      })
      this.code_arr = arr
    },
    compositionend(e) {
      let number = ''
      e.data.split('').forEach(val => {
        if(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(val)) {
          number += val
        }
      })
      let arr = new Array(6).fill(' ')
      let pre_str = this.code_copy.substr(this.code_index, this.code_copy.length - 1)
      let append_str = this.code_copy.substr(0, this.code_index)
      let full_str = pre_str + number + append_str
      arr.forEach((val, i) => {
        arr[i] = full_str.substr(i, 1)
      })
      this.code_arr = arr
      this.code_copy = this.code_arr.join('')
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.data)) {
        // this.setIndex(this.code_index)
        let arr = new Array(6).fill(' ')
        arr.forEach((val,i) => {
          arr[i] = this.code_copy.substr(i, 1)
        })
        this.code_arr = arr
        this.code_copy = this.code_arr.join('')
        // this.$nextTick(() => {
        //   this.setIndex(this.code_index)
        // })
      }
      this.ch_data = e.data.substr(0, 1)
      e.preventDefault()
      return false
    },
    codeDown(e) {
      if (e.key == 'Backspace'|| e.keyCode == 8) {
        e.preventDefault()
        let code = [...this.code_arr]
        code[this.code_index] = ' '
        this.form.code = code.join('')
        this.code_copy = this.form.code
        this.$nextTick(() => {
          if (this.code_index > 0) {
            this.setIndex(this.code_index - 1)
          } else {
            this.setIndex(0)
          }
        })
      } else if (![48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(e.keyCode)) {
        e.preventDefault()
      }
    },
    codeChange(e) {
      if (this.ch_data && e.target.value.indexOf(this.ch_data) > -1) {
        let str = this.form.code.replace(this.ch_data, '')
        if(str.length > 6) {
          str = str.substr(0, 5)
        }
        if(str.length < 5) {
          let place = new Array(str.length).fill('').join(' ')
          str = str.substr(0, str.length - 1) + place
        }
        this.form.code = str
      } else if (this.code_arr[this.code_index] != ' ' && this.code_index < 5) {
        this.code_index++
      }
      this.$nextTick(() => {
        this.setIndex(this.code_index)
      })
    },
    mobileChange(e) {
      if (e.target.value.length == 11) {
        this.validateMobile()
      } else {
        this.account_check = {
          status: false,
          msg: ''
        }
      }
    },
    async validateMobile() {
      if (!phone_reg.test(this.form.account)) {
        this.account_check = {
          status: false,
          msg: '请输入正确的11位手机号'
        }
      } else {
        let res = await AuthService.isRegistered({
          account: this.form.account,
          NO_TIPS: true
        })
        if (res.is_registered) {
          this.account_check = {
            status: true,
            msg: ''
          }
        } else {
          this.account_check = {
            status: false,
            msg: '该手机号还未注册'
          }
        }
        
      }
    },
    passwordChange(e) {
      if (!this.form.password) {
        this.password_check = {
          status: false,
          msg: '密码不能为空'
        }
      } else if(!Util.validatePwd(this.form.password)) {
        this.password_check = {
          status: false,
          msg: '密码为6~20位，数字、大写字母和小写字母两种以上组合'
        }
      } else {
        this.password_check = {
          status: true,
          msg: ''
        }
      }
      if(this.form.password != this.form.confirm_password) {
        this.confirm_password_check = {
          status: false,
          msg: '两次密码不一致'
        }
      }
    },
    confirmPasswordChange() {
      if (this.form.password != this.form.confirm_password) {
        this.confirm_password_check = {
          status: false,
          msg: '两次密码不一致'
        }
      } else {
        this.confirm_password_check = {
          status: true,
          msg: ''
        }
      }
    },
    changeType(way) {
      this.login_type = way
    },
    goCode() {
      if(!this.can_next) return
      this.sendCode(() => {
        this.code_step = 2
        this.$nextTick(() => {
          this.setIndex(0)
        })
      })
    },
    //验证码登录
    async codeLogin() {
      if (this.code_login_loading || !this.can_code_login) return
      this.code_login_loading = true
      const param = {
        account: this.form.account,
        sms_type: 'modify_password',
        code: this.form.code,
        No_Loading: true
      }
      let res = await AuthService.verifySmsCode(param).catch(err=>{
        this.code_check = {
          status: false,
          errCode: err.errCode,
          msg: err.errMsg
        }
      })
      if (res) {
        this.form.token_key = res.token_key
        this.code_step = 3
      }
      this.code_login_loading = false
    },
    async setPwdLogin() {
      if (this.login_loading || !this.can_login) return
      this.login_loading = true
      const param = {
        account: this.form.account,
        password: this.form.password,
        token_key: this.form.token_key
      }
      let res = await AuthService.modifyPassword(param).catch(err=>{
        if (err.errCode == 518) { //主账号被禁用
          this.account_check = {
            status: false,
            errCode: err.errCode,
            msg: err.errMsg || ''
          }
        }
        if (err.errCode == 1155) { //账号密码错误
          this.password_check = {
            status: false,
            errCode: err.errCode,
            msg: err.errMsg || ''
          }
        }
        if (err.errCode == 1154) { //手机号未注册
          // this.goCode()
          this.account_check = {
            status: false,
            errCode: err.errCode,
            msg: err.errMsg || ''
          }
        }
      })
      this.login_loading = false
      if (res) {
        if (this.redirectURI) {
          let redirectURI = decodeURIComponent(this.redirectURI)
          location.href = redirectURI
        } else if (this.from == 'zhiku'){
          location.href = this.zhiku_url
        } else if (this.from == 'yg') {
          location.href = this.yg_url
        } else {
          location.href = this.zhiku_url
        }
      }
    },
    async sendCode(cb) {
      // if(!this.can_send_code) return
      let param = {
        account: this.form.account,
        sms_type: 'login'
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '忘记密码',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
        cb && cb()
      }
    }
  }
}
</script>