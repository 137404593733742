import Layout from './Layout.vue'

let routes = [
  {
    path: '/',
    redirect: 'bpy_myInfo'
  },
  {
    path: 'bpy_myInfo',
    title: '我的资料',
    component: () => import('./view/myInfo')
  },
  {
    path: 'bpy_product',
    title: '产品信息',
    component: () => import('./view/product')
  },
  {
    path: 'bpy_auth',
    title: '账号认证',
    component: () => import('./view/auth')
  },
  {
    path: 'bpy_accountInfo',
    title: '账号密码',
    component: () => import('./view/accountInfo')
  },
  {
    path: 'bpy_myOrg',
    title: '已加入企业',
    component: () => import('./view/myOrg')
  },
  {
    path: '/bpy_account/change',
    component: () => import('./view/accountMerge')
  },
  {
    path: 'bpy_realNameInfo',
    component: () => import('./view/realNameInfo')
  },
  {
    path: 'bpy_authForm',
    component: () => import('./view/authForm')
  },
]
export default {
  path: '/',
  component: Layout,
  children: routes
}
export { routes }