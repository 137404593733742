import Vue from 'vue'
import View from './index.vue'

const Fn = (options = {}, confirmCb, cancelCb) => {
  const Instance = new Vue({
    data () {
      return {
        visible: options.visible || true,
        urls: options.urls || [],
        url_index: options.url_index || 0,
        name: options.name || '',
      }
    },
    render (h) {
      return h(View, {
        props: {
          visible: this.visible,
          urls: this.urls,
          url_index: this.url_index,
          name: this.name
        },
        on: {
          confirm: this.confirm,
          close: this.destroy,
          editOver: this.editOver,
          'update:visible': (val) => {
            this.visible = val
          },
          'update:url_index': (val) => {
            this.url_index = val
          }
        },
      })
    },
    methods: {
      destroy () {
        this.$destroy()
        document.body.removeChild(this.$el)
      },
      cancel() {
        cancelCb && cancelCb()
        if (options.onCancel) {
          options.onCancel(data)
        }
        this.destroy()
      },
      confirm(data) {
        data = JSON.parse(JSON.stringify(data))
        confirmCb && confirmCb(data)
        if (options.onConfirm) {
          options.onConfirm(data)
        }
        this.destroy()
      },
      editOver(data) {
        if (options.onEditOver) {
          options.onEditOver(data)
        }
      }
    }
  })

  const component = Instance.$mount()
  document.body.appendChild(component.$el)

}

export default Fn