const service = {
  /**
   * @func: createVerifyPhoneAccountSign
   * @desc: 上传手机号信息
   * @author wby
   * @version 1.0.0
   */
   createVerifyPhoneAccountSign: (param) => {
    let params = {
      url: '/user/createVerifyPhoneAccountSign',
      account: param.account,
      code: param.code,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: getWechatUserAndPhoneUserInfo
   * @desc: 获取用户信息
   * @author wby
   * @version 1.0.0
   */
   getWechatUserAndPhoneUserInfo: (param) => {
    let params = {
      url: '/wechatLoginCallback',
      state: param.state,
      code: param.code,
      from_account_merge: 1
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: getWechatUserAndPhoneUserInfo
   * @desc: 确认变更帐号
   * @author wby
   * @version 1.0.0
   */
   mergeAccount: (param) => {
    let params = {
      url: '/user/mergeAccount',
      msg: param.msg
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: checkPhoneBindStatus
   * @desc: 检查手机号
   * @author wby
   * @version 1.0.0
   */
   checkPhoneBindStatus: (param) => {
    let params = {
      url: '/user/checkPhoneBindStatus',
      phone: param.phone
    }
    return window.Http.query(params).catch(err=>{})
  },
}
export default service