const service = {
  myOrganize: (param) => {
    let params = {
      url: '/user/myOrganize',
      filter_has_product: param.filter_has_product
    }
    return window.Http.query(params).catch(err=>{})
  },
  inviteUserInfo: (param) => {
    let params = {
      url: '/user/inviteUserInfo',
      invite_code: param.invite_code
    }
    return window.Http.query(params)
  },
  joinOrganize: (param) => {
    let params = {
      url: '/user/joinOrganize',
      user_name: param.user_name,
      invite_code: param.invite_code
    }
    return window.Http.query(params).catch(err=>{})
  },
  quitOrganize: (param) => {
    let params = {
      url: '/user/quitOrganize',
      organize_id: param.organize_id
    }
    return window.Http.query(params).catch(err=>{})
  },
  
}

export default service