/*
 * @name collector promise.all封装
 * @auther sean
 * @created 21-02-23
 * @param url     {String}   请求路径
 * @param api     {String}   请求服务器地址
 * @param param   {Object}   请求参数(api, url, ...otherParams) 
 * @returns promise
 * @author sean
 * @version 1.0.0
*/


const collector = function() {
  return new Promise((resolve, reject) => {
    Promise.allSettled([...arguments]).then(res => {
      let data = res.filter(p=>p.status === 'fulfilled').map(p=>p.value)
      resolve(data)
    }).catch(err => {
      reject(err)
    })
  })
}

export default collector