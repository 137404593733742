import Vue from 'vue'
import PopupManager from './popup-manager'
let transition = Vue.component('my-transition', {
	functional: true,
	render: function (createElement, context) {
		var data = {
			props: {
				name: 'popup-fade',
				mode: 'out-in'
			}
		}
		return createElement('transition', data, context.children)
	}
})
let parentHasId = function(dom, targetId){
	if (dom.id === targetId) {
		return true
	} else if(dom && dom.parentNode && dom.parentNode.tagName !== 'BODY'){
		return parentHasId(dom.parentNode, targetId)
	} else {
		return false
	}
}
let parentHasElement = function(dom, ele) {
  if (dom === ele) {
		return true
	} else if(dom && dom.parentNode && dom.parentNode.tagName !== 'BODY'){
		return parentHasElement(dom.parentNode, ele)
	} else {
		return false
	}
}
let positionInBody = function(dom) {
	return dom.getBoundingClientRect()
}
const newInstance = (vnode, options) => {
	const _options = {
  }
	let renderFlag = false
  if(!!!vnode || Object.prototype.toString.call(vnode) != '[object Array]') return null
  for (let i = vnode.length - 1; i >= 0; i--) {
    if (vnode[i].tag) {
      renderFlag = true
    }
	}
  if(!renderFlag) return null
	const _props = {..._options, ...options}
	if(PopupManager.getDomInstance(_props.id)) return
	const Instance = new Vue({
		mounted() {
      if (_props.appendToBody) {
        document.body.appendChild(this.$el)
        let timer = setTimeout(()=>{
          this.setPosition(_props.event)
          clearTimeout(timer)
        },0)
      } else {
        this.targetDom.appendChild(this.$el)
      }
      this.show = true
      if(_props.eventType === 'click'){
        window.addEventListener('click', this.outClick)
      } else if (_props.eventType === 'hover'){
        this.$el.addEventListener('mouseleave', this.outMove)
      }
      PopupManager.registerDomInstance(_props.id, this.$el)
		},
		data() {
			return {
				show: false,
				targetId: _props.id,
				isToRight: false,
				isToBottom: false,
			}
    },
		computed: {
      targetDom(){
        return document.getElementById(this.targetId)
      }
		},
		beforeDestroy() {
			
		},
		destroyed() {
			window.removeEventListener('click', this.outClick)
			this.$el.removeEventListener('mouseleave', this.outMove)
		},
		render (h) {
			return h(transition,
				[h('div', {
					class: ['popup-wrap', { 'popup-to-right' : this.isToRight }, { 'popup-to-bottom' : this.isToBottom }],
					directives:[{
						name: 'show',
						value: this.show
					}]
				},[
          _props.is_list ? h('div',{
            class: ['popup-wrap-list']
            },[
              h('div',{
                class: ['popup-wrap-list-arrow']
              }),
              h('div',{
                class: ['popup-wrap-list-inner']
              },vnode.map(val => val))
            ]) : h('div',{
            class: ['popup-content'],
          },[vnode.map(val => val)])
          ]
				)]
			)
		},
		methods: {
      setPosition(e) {
				let pos = positionInBody(e.target)
        if (this.$el.offsetWidth + pos.left > document.body.clientWidth) {
          this.$el.style.right = document.body.clientWidth - pos.right - 15 + 'px'
          this.isToRight = true
        } else {
          this.$el.style.left = pos.left + 'px'
          this.isToRight = false
				}
        let parentHeight = document.body.clientHeight || document.getElementById('app').clientHeight
        if (this.$el.offsetHeight + pos.top + 40 > parentHeight) {
          this.$el.style.bottom = parentHeight - pos.top - 5 + 'px'
          this.isToBottom =  true
        } else {
          this.$el.style.top = pos.top + this.targetDom.offsetHeight + 'px'
          this.isToBottom = false
        }
      },
			//弹窗外点击事件
      outClick(e) {
        if (!parentHasId(e.target, this.targetId)) {
					this.destroyInstance()
				} else {
				}
      },
			//弹窗外移动
      outMove(e) {
        if(!parentHasElement(e.relatedTarget, this.targetDom) && !parentHasElement(e.relatedTarget, this.$el)) {
          this.destroyInstance()
        }
      },
			destroyInstance() {
				this.show = false
        PopupManager.deleteDomInstance(this.targetId)
				let timer = setTimeout(() => {
          this.$el.remove()
          this.$destroy()
          clearTimeout(timer)
				}, 300)
			},
			init(id) {
				this.targetId = id
				return this
			}
		}
	})
	Instance.$mount()
	Instance.$on('close', function(){
  })
	return Instance
}
export default newInstance