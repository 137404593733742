<template>
  <div class="forget-page-warp">
    <div class="login-logo">
      <div class="zhiku-logo">
        <img src="/images/img_logo_bopuyun.png" />
        <p v-if="from == 'zhiku'">制药人专业知识分享平台</p>
      </div>
      <!-- <img v-if="from == 'yg'" class="yg-logo" src="/images/img-logo-yunguan.svg" />
      <div class="zhiku-logo"  v-else>
        <img src="/images/img-logo-zhiku.svg" />
        <p>制药人专业知识分享平台</p>
      </div> -->
    </div>
    <div class="page-title">
      <template v-if="step == 0">
        <h1>重置密码</h1>
        <p>为了你的账号安全，请先验证手机号</p>
      </template>
      <template v-else-if="step == 1">
        <h1>设置新密码</h1>
      </template>
      <template v-else-if="step == 2">
        <h1>设置完成</h1>
      </template>
    </div>
    <div class="mobile-box" v-if="step == 0">
      <div class="mobile-form">
        <a-form-model ref="mobileForm" :model="mobileForm" :rules="rules">
          <a-form-model-item label="" prop="account">
            <a-input v-model="mobileForm.account" placeholder="手机号" autocomplete="off" @change="mobileChange"/>
          </a-form-model-item>
          <!-- <a-form-model-item label="" prop="captcha_code" v-show="captcha_code_url">
            <a-row>
              <a-col :span="12">
                <a-input v-model="mobileForm.captcha_code" placeholder="图形码" @blur="checkCaptchaCode" :disabled="captcha_code_check"/>
              </a-col>
              <a-col :span="12" class="img-code-box">
                <img class="img-code" :src="captcha_code_url" alt="" srcset=""/>
                <div class="img-code-btn" @click="getImgCode">换一张</div>
              </a-col>
            </a-row>
          </a-form-model-item> -->
          <a-form-model-item class="code-box" label="" prop="code">
            <a-input class="code-input" v-model="mobileForm.code" placeholder="短信验证码" @change="smsChange">
              <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode">{{send_code_text}}</div>
            </a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="submit-btn">
        <a-button type="primary" size="large" @click="submitForm('mobileForm')">
          下一步
        </a-button>
      </div>
    </div>
    <div class="pwd-box" v-else-if="step == 1">
      <div class="pwd-form">
        <a-form-model ref="pwdForm" :model="pwdForm" :rules="pwdRules">
          <a-form-model-item label="" prop="password">
            <a-input v-model="pwdForm.password" type="password" placeholder="请输入登录密码" autocomplete="off"/>
          </a-form-model-item>
          <a-form-model-item label="" prop="password_confirm">
            <a-input v-model="pwdForm.password_confirm" type="password" placeholder="请再次输入登录密码" autocomplete="off"/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="submit-btn">
        <a-button type="primary" size="large" @click="submitPwd('pwdForm')">
          下一步
        </a-button>
      </div>
    </div>
    <!-- <div class="success-box" v-else-if="step == 2">
      <div class="success-info">
        <div class="success-icon">
          <img  src="/images/icon-status-success.svg" />
        </div>
        <div class="right-text">
          <div class="main-text">
            新密码设置成功
          </div>
          <div class="sub-text">1 秒后自动跳转至登录页面</div>
        </div>
      </div>
      <div class="submit-btn">
        <a-button type="primary" size="large" @click="login_back">
          下一步
        </a-button>
      </div>
    </div> -->
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
export default {
  name:'mobile',
  created() {
    document.title = '忘记密码 - 博普云'
  },
  mounted() {
    // this.getImgCode()
  },
  beforeDestroy () {
    
  },
  data() {
    let validateMobile = async(rule, value, callback) => {
      this.mobile_check = false
      if (value == '') {
        callback(new Error('请输入手机号'))
      } else if (!phone_reg.test(value)) {
        callback(new Error('请输入正确的11位手机号'))
      } else {
        let res = await AuthService.isRegistered({
          account: this.mobileForm.account,
          NO_TIPS: true
        })
        if (res.is_registered) {
          this.mobile_check = true
          callback()
        } else {
          callback(new Error('该手机号还未注册'))
        }
      }
    }
    let validateCode = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 505) {
        callback(new Error('短信验证码错误'))
      } else {
        callback()
      }
    }
    let validatePwd = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else if (!Util.validatePwd(value)){
        callback(new Error('密码为6~20位，数字、大写字母和小写字母两种以上组合'));
      } else {
        callback()
      }
      // else if (value.length < 6 || value.length > 20) {
      //   callback(new Error('密码在6-20位之间'))
      // } 
    }
    let validatePwdConfirm = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.pwdForm.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      step: 0,
      mobileForm: {
        account: '',
        code: '',
        captcha_code: ''
      },
      pwdForm: {
        password: '',
        password_confirm: ''
      },
      rules: {
        account: [{ validator: validateMobile, trigger: 'blur' }],
        code: [{ validator: validateCode, trigger: 'change' }],
      },
      pwdRules: {
        password: [{ validator: validatePwd, trigger: 'blur' }],
        password_confirm: [{ validator: validatePwdConfirm, trigger: 'blur' }],
      },
      mobile_check: false,
      code_count: 0,
      failure_code: false
    }
  },
  methods: {
    login_back() {
      let param = {
        path: '/auth_login',
      }
      param.query = this.$route.query
      // if (this.redirectURI) {
      //   param.query = {
      //     redirectURI: this.redirectURI
      //   }
      // }
      this.$router.push(param)
    },
    submitPwd(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const param = {
            account: this.mobileForm.account,
            token_key: this.mobileForm.token_key,
            password: this.pwdForm.password
          }
          let res = await AuthService.modifyPassword(param).catch(err=>{
          })
          if (res) {
            // this.step = 2
            this.goNext()
          }
        } else {
          return false
        }
      })  
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const param = {
            account: this.mobileForm.account,
            sms_type: 'modify_password',
            code: this.mobileForm.code
          }
          let res = await AuthService.verifySmsCode(param).catch(err=>{
            this.failure_code = err.errCode
            this.$nextTick(() => {
              this.$refs[formName].validate()
            })
          })
          if (res) {
            this.mobileForm.token_key = res.token_key
            this.step = 1
          } else {

          }
        } else {
          return false
        }
      })
    },
    mobileChange(value) {
      this.failure_code = false
    },
    smsChange() {
      this.failure_code = false
    },
    async sendCode() {
      if(!this.can_send_code) return
      let param = {
        account: this.mobileForm.account,
        sms_type: 'modify_password'
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '忘记密码',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    countJump() {

    }
  },
  computed: {
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    }
  }
}
</script>