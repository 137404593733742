let zIndex
let hasInitIndex = false
// let dom_instance = {}
// const dom_instance = {};

const PopupManager = {
	dom_instance: {},
	popup_list: [],
	getDomInstance(id){
		return PopupManager.dom_instance[id]
	},
	registerDomInstance(id, dom){
		if(PopupManager.getDomInstance(id)){
			return false
		} else {
			PopupManager.dom_instance[id] = dom
			PopupManager.openDom(id, PopupManager.nextZIndex(), dom)
		}
  },
  //移除instance
  deleteDomInstance(id){
    PopupManager.closePopup(id)
    delete PopupManager.dom_instance[id]
  },
  //移除instance并删除节点
	destoryDomInstance(id, timing){
		try{
      PopupManager.closePopup(id)
      PopupManager.getDomInstance[id].remove()
      delete PopupManager.dom_instance[id]
    } catch (e) {

    }
	},
	nextZIndex() {
    return PopupManager.zIndex++
  },
	closePopup(id){
		if (PopupManager.popup_list.length > 0) {
      const topItem = PopupManager.popup_list[PopupManager.popup_list.length - 1]
      if (topItem.id === id) {
        PopupManager.popup_list.pop()
      } else {
        for (let i = PopupManager.popup_list.length - 1; i >= 0; i--) {
          if (PopupManager.popup_list[i].id === id) {
            PopupManager.popup_list.splice(i, 1)
            break
          }
        }
      }
    }
	},
	getTopPopup() {
		if (PopupManager.popup_list.length > 0) {
			const topPopup = PopupManager.popup_list[PopupManager.popup_list.length - 1]
			if (!topPopup) return
			const instance = PopupManager.getDomInstance(topPopup.id)
			return instance
		}
	},
	openDom(id, zIndex, dom, className) {
		if (!id || zIndex === undefined) return
		// if(PopupManager.getDomInstance(id)) return
		if (zIndex) {
      dom.style.zIndex = zIndex
    }
		PopupManager.popup_list.push({id: id, zIndex: zIndex, className: className})
	}
}
Object.defineProperty(PopupManager, 'zIndex', {
	get() {
		if (!hasInitIndex) {
			zIndex = zIndex || 2000;
			hasInitIndex = true
		}
		return zIndex
	},
	set(value) {
		zIndex = value
	}
})
export default PopupManager