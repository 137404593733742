const service = {
  getAppUrl: () => {
    let params = {
      url: '/getAppUri'
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: userDetail
   * @desc: 用户详情信息
   * @author sean
   * @version 1.0.0
   */
  userDetail: (param) => {
    let params = {
      url: '/user/detail',
      user_id: param.user_id
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: getOssToken
   * @desc: 获取oss上传凭证
   * @author sean
   * @version 1.0.0
   */
  getOssToken: (param) => {
    let params = {
      url: '/oss/getPolicy',
      type: param.oss_type || 1,
      hash: param.hash,
      name: param.name
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: getUserInfo
   * @desc: 获取oss上传凭证
   * @author sean
   * @version 1.0.0
   */
  getUserInfo: (param) => {
    let params = {
      url: '/auth/module/detailOfUser'
    }
    return window.Http.query(params)
  },
  /**
   * @func: getLogList
   * @desc: 获取操作记录
   * @author sean
   * @version 1.0.0
   */
  getLogList: (param) => {
    let params = {
      url: '/log/index',
      target_id: param.target_id,
      target_type: param.target_type,
      page: param.page,
      page_size: param.page_size,
    }
    if (param.date && param.date.length > 0) {
      params.start_time = param.date[0]
      params.end_time = param.date[1]
    }
    return window.Http.query(params)
  },

  /**
   * @func: wechatIndex
   * @desc: 微信开放标签验证授权
   * @author sean
   * @version 1.0.0
   */
  wechatIndex: (param) => {
    let params = {
      url: '/wechat/index'
    }
    return window.Http.query(params)
  },

  /**
   * @func: wechatCheckSignature
   * @desc: 微信开放标签校验签名
   * @author sean
   * @version 1.0.0
   */
  wechatCheckSignature: (param) => {
    let params = {
      url: '/wechat/checkSignature',
      signature: param.signature,
      timestamp: param.timestamp,
      nonce: param.nonce,
      echostr: param.echostr
    }
    return window.Http.query(params)
  }
}

export default service