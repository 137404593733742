import Vue from 'vue'
import popup from './popup'
import popupManager from './popup-manager'

let parentHasId = function(dom, targetId) {
	if (dom.id === targetId) {
		return true
	} else if(dom.parentNode && dom.parentNode.tagName !== 'BODY'){
		return parentHasId(dom.parentNode, targetId)
	} else {
		return false
	}
}
let parentHasElement = function(dom, ele) {
	if (dom === ele) {
		return true
	} else if(dom && dom.parentNode && dom.parentNode.tagName !== 'BODY'){
		return parentHasElement(dom.parentNode, ele)
	} else {
		return false
	}
}
export default Vue.component('pop-content', {
	functional: true,
	inheritAttrs: false,
	props: {
		title: {
			type: [String],
			default: '更多操作'
		},
		trigger: {
			type: [String],
			default: 'click'
		},
		appendToBody: {
			type: [Boolean],
			default: true
		},
		is_list: {
			type: [Boolean],
			default: true
		}
	},
	render: function (h, context) {
		let id = 'pop-wrap-' + popupManager.nextZIndex()
		// let action
		let instance
		let dom
		let title_dom = context.children.filter(val => {
      return val.data && val.data.slot !== 'content'
    })
    let content_dom = []
		context.children.filter(val => {
      return val.data && val.data.slot == 'content'
    }).forEach(val => {
			if(val.tag === 'template') {
				content_dom.push(...val.children)
			} else {
				content_dom.push(val)
			}
		})
		if (context.props.trigger === 'click') {
			dom = h('span',{
				class: ['pop-wrap',context.data.staticClass],
				style: [context.data.staticStyle],
				attrs:{
					id: id
				},
				on: {
					click(e){
						instance = popup(content_dom, {is_list: context.props.is_list, event: e, id : id, eventType: 'click', appendToBody: context.props.appendToBody})
					}
				}
			}, title_dom)
		} else if (context.props.trigger === 'hover') {
			dom = h('span',{
				class: ['pop-wrap',context.data.staticClass],
				style: [context.data.staticStyle],
				attrs:{
					id: id
				},
				on: {
					mouseenter(e){
						instance = popup(content_dom, {is_list: context.props.is_list, event: e, id : id, eventType: 'hover', appendToBody: context.props.appendToBody}) || instance
						document.getElementById(id).addEventListener('mouseleave',function(ev){
							if(instance && !parentHasElement(ev.relatedTarget, instance.$el) && !parentHasElement(ev.relatedTarget, document.getElementById(id))){
								try{
									instance.destroyInstance()
									instance = null
								} catch (e) {

								}
							}
						})
					}
				}
			}, title_dom)
		}
		return dom
	}
})