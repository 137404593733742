const service = {
  /**
   * @func: Login
   * @desc: 用户登录
   * @author sean
   * @version 1.0.0
   */
  Login: (param) => {
    let params = {
      url: '/login',
      account: param.account,
      password: param.password,
      way: param.login_type, //1验证码 2密码
      code: param.code,
      // NO_TIPS: true,
      NO_Loading: param.NO_Loading || false,
      label: param.label || undefined,
      invite_primary_id: param.invite_primary_id || undefined,
      product_id: param.product_id,
      vid: param.vid
    }
    return window.Http.query(params)
  },
  isLogin: () => {
    let params = {
      url: '/isLogin'
    }
    return window.Http.query(params)
  },
  /**
   * @func: Logout
   * @desc: 用户登录
   * @author sean
   * @version 1.0.0
   */
   Logout: (param) => {
    let params = {
      url: '/logout',
      NO_TIPS: true
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: isRegistered
   * @desc: 手机号码是否注册
   * @author sean
   * @version 1.0.0
   */
  isRegistered: (param) => {
    let params = {
      url: '/isRegistered',
      account: param.account,
      NO_TIPS: true,
      NO_Loading: true,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: checkBindStatus
   * @desc: 手机号码注册绑定状态
   * @author sean
   * @version 1.0.0
   */
  checkBindStatus: (param) => {
    let params = {
      url: '/bpyCheckAccountPhoneBindStatus',
      account: param.account,
      return_status: param.return_status,
      NO_TIPS: true,
      NO_Loading: param.NO_Loading == false ? param.NO_Loading : true
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: checkBindStatusCommon
   * @desc: 手机号码注册绑定状态
   * @author sean
   * @version 1.0.0
   */
  checkBindStatusCommon: (param) => {
    let params = {
      url: '/user/bpyCheckAccountPhoneBindStatusCommon',
      account: param.account,
      return_status: param.return_status,
      NO_TIPS: true,
      NO_Loading: param.NO_Loading == false ? param.NO_Loading : true
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: bpyBind
   * @desc: 绑定手机号
   * @author sean
   * @version 1.0.0
  */
  bpyBind: (param) => {
    let params = {
      url: '/bpyBind',
      account: param.account,
      code: param.code,
      type: param.type,
      invite_primary_id: param.invite_primary_id || undefined,
      label: param.label || undefined,
      product_id: param.product_id
    }
    return window.Http.query(params)
  },

  /**
   * @func: sendSmsCode
   * @desc: 获取短信验证码
   * @author sean
   * @version 1.0.0
   */
  sendSmsCode: (param) => {
    let params = {
      url: '/sendSmsCode',
      account: param.account,
      sms_type: param.sms_type,
      // from: param.from ? param['from'].toUpperCase() : 'BPY'
    }
    // 全局处理一下来源，区分【博普智库/博普智药】
    const _URL = new URL(location.href)
    params.from = (_URL.searchParams.get('f') || _URL.searchParams.get('from') || 'BPY').toUpperCase()
    return window.Http.query(params).catch(err=>{})
  },

  /**
   * @func: verifySmsCode
   * @desc: 验证短信验证码
   * @author sean
   * @version 1.0.0
   */
   verifySmsCode: (param) => {
    let params = {
      url: '/verifySmsCode',
      account: param.account,
      sms_type: param.sms_type,
      code: param.code,
      NO_TIPS: true,
      NO_Loading: param.NO_Loading || false
    }
    return window.Http.query(params)
  },

  /**
   * @func: modifyPassword
   * @desc: 设置/修改密码
   * @author sean
   * @version 1.0.0
   */
   modifyPassword: (param) => {
    let params = {
      url: '/modifyPassword',
      account: param.account,
      token_key: param.token_key,
      password: param.password,
      // NO_TIPS: true,
    }
    return window.Http.query(params).catch(err=>{})
  },

/**
 * @func: getUserInfo
 * @desc: 获取用户信息
 * @author sean
 * @version 1.0.0
 */
  getUserInfo: (param) => {
    let params = {
      url: '/user/getUserInfo',
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: bpyBindPhone
   * @desc: 绑定手机号
   * @author sean
   * @version 1.0.0
   */
   bpyBindPhone: (param) => {
    let params = {
      url: '/user/bpyBindPhone',
      code: param.code,
      account: param.account,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: editUserInfo
   * @desc: 编辑用户信息
   * @author sean
   * @version 1.0.0
   */
   editUserInfo: (param) => {
    let params = {
      url: '/user/editUserInfo',
      nickname: param.nickname,
      avatar: param.avatar,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: getWechatCallBackInfo
   * @desc: 获取微信信息
   * @author sean
   * @version 1.0.0
   */
  getWechatCallBackInfo: (param) => {
    let params = {
      ...param,
      url: '/bindWechatCallback',
      NO_TIPS: true
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: bpyBindWechat
   * @desc: 绑定微信
   * @author sean
   * @version 1.0.0
   */
  bpyBindWechat: (param) => {
    let params = {
      url: '/user/bpyBindWechat',
      user_info: param.user_info,
      force_bind_wechat: param.force_bind_wechat || undefined,
      use_user_info: param.use_user_info || undefined,
      NO_TIPS: true
    }
    return window.Http.query(params)
  },
  /**
   * @func: bpyCheckAccountIsBindPhone
   * @desc: 微信解绑时判断账号是否已经绑定手机
   * @author sean
   * @version 1.0.0
   */
   bpyCheckAccountIsBindPhone: (param) => {
    let params = {
      url: '/user/bpyCheckAccountIsBindPhone'
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: bpyUnbindWechat
   * @desc: 微信解绑
   * @author sean
   * @version 1.0.0
   */
   bpyUnbindWechat: (param) => {
    let params = {
      url: '/user/bpyUnbindWechat',
      code: param.code
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: realNameInfo
   * @desc: 实名信息
   * @author sean
   * @version 1.0.0
   */
  realNameInfo: (param) => {
    let params = {
      url: '/realName/info',
      plaintext: param.plaintext
    }
    return window.Http.query(params).catch(err=>{})
  },
  
  /**
   * @func: realNameCreate
   * @desc: 实名信息更新
   * @author sean
   * @version 1.0.0
   */
   realNameCreate: (param) => {
    let params = {
      url: '/realName/create',
      name: param.name,
      certificate_number: param.certificate_number,
      certificate_img_front: param.certificate_img_front,
      certificate_img_backend: param.certificate_img_backend,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: realNameUpdate
   * @desc: 实名信息更新
   * @author sean
   * @version 1.0.0
   */
   realNameUpdate: (param) => {
    let params = {
      url: '/realName/update',
      id: param.id,
      name: param.name,
      certificate_number: param.certificate_number,
      certificate_img_front: param.certificate_img_front,
      certificate_img_backend: param.certificate_img_backend,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: realNameUpdate
   * @desc: 实名信息更新
   * @author sean
   * @version 1.0.0
   */
   realNameUpdate: (param) => {
    let params = {
      url: '/realName/update',
      id: param.id,
      name: param.name,
      certificate_number: param.certificate_number,
      certificate_img_front: param.certificate_img_front,
      certificate_img_backend: param.certificate_img_backend,
    }
    return window.Http.query(params).catch(err=>{})
  },
  /**
   * @func: userInfo
   * @desc: hyper获取用户信息
   */
  userInfo: () => {
    let params = {
      api: 'hyperf',
      url: '/hyper/user/info'
    }
    return window.Http.query(params)
  },
  /**
   * @func: userInfo
   * @desc: 编辑用户信息
   */
  editUserInfo: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/createOrEdit',
      ...param
    }
    return window.Http.query(params)
  },
  /**
   * @func: applyCertList
   * @desc: 申请认证类型列表
   */
   applyCertList: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/cert/applyCertList'
    }
    return window.Http.query(params)
  },
  /**
   * @func: getCertConfigListFromApplyCertList
   * @desc: 获取需要填写的字段配置，从申请认证类型列表来
   */
   getCertConfigListFromApplyCertList: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/cert/getCertConfigListFromApplyCertList',
      cert_type: param.cert_type || 1
    }
    return window.Http.query(params)
  },
  /**
   * @func: commitCertInfo
   * @desc: 提交认证信息
   */
   commitCertInfo: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/cert/commitCertInfo',
      ...param
    }
    return window.Http.query(params)
  },
  /**
   * @func: commitCertInfo
   * @desc: 企业用户信息
   */
  userEnterpriseInfo: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/userEnterprise/info'
    }
    return window.Http.query(params)
  },
  /**
   * @func: commitCertInfo
   * @desc: 企业用户信息修改报
   */
  addOrUpdate: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/userEnterprise/addOrUpdate',
      username: param.username,
      intro: param.intro,
      introduction_content: param.introduction_content,
      avator: param.avator,
    }
    return window.Http.query(params)
  },
  /**
   * @func: userProductList
   * @desc: 用户产品列表
   */
  userProductList: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/userProduct/list',
      filter_id: param.filter_id,
      without_page: param.without_page,
      page: param.page,
      page_size: param.page_size,
    }
    return window.Http.query(params)
  },
  /**
   * @func: userProductCreateOrEdit
   * @desc: 用户产品信息新增或编辑
   */
  userProductCreateOrEdit: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/userProduct/createOrEdit',
      name: param.name,
      content: param.content,
      id: param.id
    }
    return window.Http.query(params)
  },
   /**
   * @func: userProductDelete
   * @desc: 用户产品信息新增或编辑
   */
   userProductDelete: (param) => {
    const params = {
      api: 'hyperf',
      url: '/hyper/user/userProduct/delete',
      id: param.id
    }
    return window.Http.query(params)
  }
}

export default service