<template>
  <div id="app" v-if="is_ready">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
    <Loading />
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import { mapState, mapActions } from 'vuex'
  export default {
    name:'App',
    data() {
      return {
        locale: zhCN
      }
    },
    // beforeRouteEnter(to, from , next) {
    //   console.log(to)
    //   // if (!this.is_pc && this.$route.path.indexOf('_mobile') == -1) {
    //   //   let path = this.$route.path + '_mobile'
    //   //   this.$router.replace({
    //   //     path: path,
    //   //     query: this.$route.query
    //   //   })
    //   // } else if(this.is_pc && this.$route.path.indexOf('_mobile') > -1) {
    //   //   let path = this.$route.path.replace('_mobile', '')
    //   //   this.$router.replace({
    //   //     path: path,
    //   //     query: this.$route.query
    //   //   })
    //   // }
    // },
    created() {
      this.initGlobalData()
    },
    computed: {
      ...mapState(['is_ready'])
    },
    methods: {
      ...mapActions([
        'initGlobalData'
      ])
    }
  }
</script>