<template>
   <div class="bind-page-warp login-box" :class="{'yg-warp': from == 'yg'}">
    <div class="login-logo">
      <div class="zhiku-logo">
        <img src="/images/img_logo_bopuyun.png" />
        <p v-if="from == 'zhiku'">制药人专业知识分享平台</p>
      </div>
      <!-- <img v-if="from == 'yg'" class="yg-logo" src="/images/img-logo-yunguan.svg" />
      <div class="zhiku-logo"  v-else>
        <img src="/images/img-logo-zhiku.svg" />
        <p>制药人专业知识分享平台</p>
      </div> -->
    </div>
    <div class="page-title">
      <h1>绑定手机号</h1>
      <p>绑定手绑定后，可以使用手机登录机号</p>
    </div>
    <div class="login-form">
      <a-form-model ref="bindForm" :model="bindForm" :rules="ruleInline">
        <a-form-model-item label="" prop="account">
          <a-input v-model="bindForm.account" placeholder="请输入手机号" autocomplete="new-password" @change="mobileChange"/>
        </a-form-model-item>
        <a-form-model-item class="code-box" label="" prop="code">
          <a-input class="code-input" v-model="bindForm.code" placeholder="短信验证码" @change="smsChange">
            <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode">{{send_code_text}}</div>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="btn-box">
      <div class="login-btn">
        <a-button type="primary" size="large" @click="handleSubmit('bindForm')">
          下一步
        </a-button>
      </div>
    </div>
    <a-modal title="此手机号已注册"
      centered
      width="560px"
      v-model="bind_confirm_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      @ok="bindConfirm"
      >
        <div class="modal-text">你输入的手机号「{{bindForm.account}}」已注册账号「{{bind_info.nickname}}」，请使用该手机号直接登录。</div>
        <div class="modal-text" style="color: #17181a;">当前暂不支持账号合并，有疑问请<span style="color: #2c65ff;" @click="showHelpModal">联系客服</span></div>
        <!-- <div class="modal-error-text" style="margin-bottom: 16px">仍想绑定手机号「{{bindForm.account}}」至当前账号?<span style="color: #469AE9;margin-left: 5px;cursor: pointer;" @click="goAccountMerge">点此继续</span></div> -->
    </a-modal>
    <a-modal title="绑定失败"
      centered
      width="560px"
      v-model="bind_fail_show"
      :maskClosable="false"
      :keyboard="false"
      >
        <template slot="footer">
          <a-button type="primary" @click="bind_fail_show = false">关闭</a-button>
        </template>
        <div class="modal-text">你输入的手机号已注册账号「{{bind_info.nickname}}」，请使用该手机号直接登录。</div>
        <!-- <div class="modal-info-text">仍想绑定手机号「{{bindForm.account}}」至当前账号，请联系客服，客服微信号：BPYD2019</div> -->
        <div class="modal-text" style="color: #17181a;">当前暂不支持账号合并，有疑问请<span style="color: #2c65ff;" @click="showHelpModal">联系客服</span></div>
    </a-modal>
    <div class="adTempModal" v-if="helpModal">
      <div class="adtemp-modal">
        <img class="temp" src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/ca/cae2d12bc6abfabf2ff9828718917a3c/7C24E33B-B024-48A9-86CD-8FE26802D53F.png">
        <div class="temp-text">扫码添加客服</div>
        <img class="close" @click="helpModal = false" src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/3e/3e7601cb8766aa6b9528a41254e37ff6/icon-close-fill%403x.png">
      </div>
    </div>
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
import { mapState, mapActions } from 'vuex'
export default {
  name: 'BpyBindPhone',
  created() {
    document.title = '绑定手机号 - 博普云'
    this.initUserInfo()
  },
  mounted() {

  },
  watch: {
    user_info: function (user_info) {
      if (user_info && user_info.account) {
        this.goNext()
      }
    }
  },
  computed: {
    ...mapState({
      zhiku_url: state => state.app_domain.zhiku_url,
      mall_url: state => state.app_domain.mall_url,
      user_info: state => state.user_info
    }),
    from_zhiku() {
      return this.$route.query.from == 'zhiku' || this.$route.query.f == 'zhiku'
    }
  },
  data () {
    let validateMobile = async(rule, value, callback) => {
      this.mobile_check = false
      if (value === '') {
        callback(new Error('请输入手机号'))
        return
      } else if (!new RegExp(phone_reg).test(value)){
        callback(new Error('请输入正确的11位手机号'))
        return
      } else if (this.user_info.account == value) {
        // callback(new Error('手机号不能为当前账号手机号'))
      }
      this.mobile_check = true
      callback()
    }
    const validateMsgVerification = (rule, value, callback) => {
      if (new RegExp(/^$| /).test(this.bindForm.code)) {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 535) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    return {
      helpModal: false,
      is_submitting: false,
      failure_code: '', // 登录错误码,
      code_count: '',
      bindForm: {
        account: '',
        code: '',
      },
      mobile_check: false,
      ruleInline: {
        account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification, trigger: 'blur' }
        ]
      },
      bind_confirm_show: false,
      bind_fail_show: false,
      bind_info: {}
    }
  },
  methods: {
    ...mapActions([
      'initUserInfo'
    ]),
    showHelpModal () {
      this.helpModal = true
    },
    goAccountMerge () {
      this.$router.push({
        path: '/bpy_account/change',
        query: this.$route.query
      })
    },
    skipPage(url) {
      const route = {
        path: url,
        query: this.$route.query
      };
      this.$router.push(route)
    },
    //获取验证码倒计时
    async sendCode() {
      if(!this.can_send_code) return
      let param = {
        account: this.bindForm.account,
        sms_type: 'modify_account'
      }
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    mobileChange(event) {
      let current_val = event.target.value
      this.failure_code = false
      this.mobile_check = false
      if (current_val && new RegExp(phone_reg).test(current_val)) {
        this.mobile_check = true
      }
    },
    smsChange() {
      this.failure_code = false
    },
    setInputError(name) {
      this.$refs[name].validate((valid) => {})
    },
    handleSubmit(name) {
      if (this.is_submitting) return
      this.$refs[name].validate(async (valid) => {  
        if (valid) {
          const param = {
            account: this.bindForm.account,
            code: this.bindForm.code
          }
          let res = await AuthService.checkBindStatusCommon({
            account: this.bindForm.account,
            NO_TIPS: true
          })
          this.is_submitting = false
          // 手机号未注册，直接绑定
          if (res.status === 1) {
            let res = await AuthService.bpyBindPhone(param)
            if (res) {
              this.$message.success('绑定成功')
              this.initUserInfo()
              this.bind_vertify_show = false
              setTimeout(() => {
                this.goNext()
              }, 500)
            }
          } else {
            this.bind_confirm_show = true
            this.bind_info = res
          }
        }
      })
    },
    async bindConfirm() {
      this.bind_confirm_show = false
      return
      if (this.is_submitting) return
      const param = {
        account: this.bindForm.account,
        code: this.bindForm.code
      }
      this.is_submitting = true
      let res = await AuthService.bpyBindPhone(param).catch(err => {
        this.failure_code = err.errCode
        this.$nextTick(() => {
          this.$refs.bindForm.validateField('code')
        })
      })
      this.bind_confirm_show = false
      this.is_submitting = false
      if (res) {
        this.$message.success('绑定成功')
        setTimeout(() => {
          this.goNext()
        }, 500)
      }
    },
    //关闭模态框
    closeModal(){
      this.bind_confirm_show = false
    },
  },
  computed: {
    ...mapState({
      zhiku_url: state => state.app_domain.zhiku_url,
      mall_url: state => state.app_domain.mall_url,
      yg_url: state => state.app_domain.yg_url
    }),
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    },
  }
}
</script>

<style scoped>
  .adTempModal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2001;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .adtemp-modal {
    padding: 24px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: 6px;
    flex-direction: column;
    background-color: #fff;
  }
  .temp {
    width: 200px;
    height: 200px;
    padding: 9.1px;
    border-radius: 9.1px;
    border: 1px solid #eff1f4;
    background-color: #fafafa;
  }
  .temp-text {
    font-size: 14px;
    color: #37383a;
    margin-top: 10px;
  }
  .close {
    width: 29px;
    height: 29px;
    bottom: -58px;
    margin-top: 24px;
    position: absolute;
  }
</style>