const PREFIX = `[${process.env.NODE_ENV}]`

// 读取数据
export const getStorage = (key) => {
  try {
    var value = localStorage.getItem(`${PREFIX}_${key}`)
    if (!value && value != 0) {
      return ''
    }
    return JSON.parse(value)
  } catch (e) {
    console.log(e)
  }
  return false
}

// 写数据
export const setStorage = (key, value) => {
  try {
    if (!value && value != 0) {
      return false
    }
    localStorage.setItem(`${PREFIX}_${key}`, JSON.stringify(value))
  } catch (e) {
    console.log(e)
  }
  return true
}

// 清除数据
export const removeStorage = (key) => {
  try {
    localStorage.removeItem(`${PREFIX}_${key}`)
  } catch (e) {
    console.log(e)
  }
  return true
}

// 清空缓存
export const clearStorage = () => {
  try {
    localStorage.clear()
  } catch (e) {
    console.log(e)
  }
  return true
}

