import Vue from 'vue'
import Vuex from 'vuex'
import collector from '@/assets/collector'
import { getStorage, removeStorage } from '@/assets/storage'
import router from '@/router'
import QUERY_API from '../../../../server_config/release'

Vue.use(Vuex)

const state = () => ({
  loading_status: 0,
  page_status: 0,  // 0->初始状态 1->显示选择企业 2->进入企业 3->用户账号停用
  user_info: {
    nickname: '',
    uid: '',
    avatar: '/images/user-avatar-default.png',
    account: '',
    intro: '',
    signature: '',
    wechat_nickname: '',
    password: 0,
    type: 1 // 1 智库未通过企业认证 2 通过
  },
  Permission: {},
  app_domain: {},
  is_ready: false,
  env: QUERY_API.env || ''
})

const getters = {
  userMaskMobile (state) {
    let mobile = state.user_info?.login_mobile || ''
    return mobile.substring(0, 3) +'****'+ mobile.substr(mobile.length - 4)
  }
}

const actions = {
  showLoading ({commit, state}) {
    let count = state.loading_status + 1
    commit('setLoadingStatus', count)
  },
  hideLoading ({commit, state}) {
    let count = state.loading_status - 1
    commit('setLoadingStatus', count)
  },
  setUser ({commit, state}, data) {
    commit('setUserInfo', data)
  },
  async initUserInfo ({commit, state}) {
    // 如果为登录，则重定向到登录页面
    // if (!$Cookie.get('bpy_user_center_cookie')) {
    //   router.replace({
    //     path: '/auth_login',
    //     query: {
    //       redurect_url: location.pathname
    //     }
    //   })
    //   return Promise.resolve(true)
    // }
    // // 如果没有选择企业，则渲染选择企业视图
    // if (!getStorage('CurrentEnterpriseId')) {
    //   commit('setPageStatus', 1)
    //   return Promise.resolve(true)
    // }
    // 开始初始化用户信息
    try {
      let res = await AuthService.getUserInfo({})
      let user_info = {
        nickname: res.nickname,
        uid: res.uid,
        avatar: res.avatar || '/images/user-avatar-default.png',
        account: res.account,
        intro: res.intro,
        signature: res.signature,
        wechat_nickname: res.wechat_nickname,
        password: res.password,
        type: res.type
      }
      commit('setUserInfo', user_info)
      
      // let _permission_map = res.modules.reduce((cur, item) => {
      //   item.key && (cur[item.key] = true)
      //   return cur
      // }, {})
      // commit('setPermission', _permission_map)
      // // 显示账号被禁用
      // if (res.status === 2) {
      //   commit('setPageStatus', 3)
      // } else {
      //   commit('setPageStatus', 2)
      // }
      // return Promise.resolve(true)
    } catch (e) {
      console.log(e)
      return Promise.resolve(true)
    }
  },
  // 初始化后台页面全局数据
  async initGlobalData ({commit, state}, data) {
    let res = await BaseService.getAppUrl({})
    commit('setReady', true)
    if (res) {
      commit('setAppDomain', res)
    }
  }
}

const mutations = {
  setLoadingStatus (state, status) {
    state.loading_status = status
  },
  setPageStatus (state, data = 0) {
    state.page_status = data
  },
  setUserInfo (state, data) {
    state.user_info = data
  },
  setPermission (state, data) {
    state.Permission = data
  },
  setAppDomain(state, data) {
    state.app_domain = data
  },
  setReady(state, data) {
    state.is_ready = data
  }
}
// 导出
export default {
  state,
  getters,
  actions,
  mutations
}