<template>
  <div class="org-invite-warp" :class="{'invite-mobile-warp': !is_pc}">
    <div class="invite-title">
      {{user_name || ' '}}
    </div>
    <div class="sub-title">邀请你加入企业</div>
    <div class="split-line"></div>
    <div class="org-item">
      <img src="/images/icon-agency.svg" />
      <span>{{org_info.name}}</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules" v-if="login_status == 1">
      <a-form-model-item label="" prop="name">
        <a-input v-model="form.name" placeholder="请输入你的姓名"/>
      </a-form-model-item>
    </a-form-model>
    <a-button type="primary" @click="doJoin">立即加入</a-button>
    <div class="sub-btn" @click="skipJoin" v-if="login_status == 1">我不是企业人员</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Invite',
  created () {
    document.title = '加入企业 - 博普云'
    this.getLoginStatus()
    this.initInfo()
  },
  data () {
     let validateName = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入姓名'))
      } else if (value.length < 2 || value.length > 30){
        callback(new Error('姓名长度为2-30个字符'));
      } else {
        callback()
      }
      // else if (value.length < 6 || value.length > 20) {
      //   callback(new Error('密码在6-20位之间'))
      // } 
    }
    return {
      user_name: '',
      org_info: {
        name: '',
        id: ''
      },
      form: {
        name: ''
      },
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
      },
      login_status: 0
    }
  },
  computed: {
    code() {
      return this.$route.query.code
    }
  },
  methods: {
    async initInfo() {
      let res = await OrgService.inviteUserInfo({
        invite_code: this.code
      }).catch(err => {
        if (err.errCode == 535) {
          this.$router.push({
            path: '/',
            query: { 
              ...this.$route.query,
              f: 'yg'
            }
          })
        }
      })
      if (res) {
        this.org_info = res.organize_info
        this.user_name = res.user_name
      }
    },
    async getLoginStatus() {
      let res = await AuthService.isLogin()
      if (res) {
        this.login_status = res.is_login
      }
    },
    async doJoin() {
      if (!this.login_status) {
        this.$router.push({
          path: '/auth_login',
          query: {
            redirect_url: location.href,
            f: 'yg'
          }
        })
        return
      }
      this.$refs.form.validate( async valid => {
        if(valid) {
          let res = await OrgService.joinOrganize({
            invite_code: this.code,
            user_name: this.form.name
          })
          if (res) {
            this.$message.success('加入企业成功')
            if (this.is_pc) {
              this.$router.push({
                path: '/bpy_myOrg',
                query: {
                  ...this.$route.query,
                  f: 'yg'
                }
              })
            }
          }
        }
      })
    },
    skipJoin() {
      this.$router.push({
        path: '/',
        query: { 
          ...this.$route.query,
          f: 'yg'
        }
      })
    }
  }
}
</script>