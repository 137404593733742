import Loading from './Loading/Loading.vue'
import LoadingApi from './Loading/api.js'
import Popup from './Popup/index.js'
import ImgPreview from './zoomInPreview/index.js'


//组件
let components = {
  Loading,
}

//组件服务 api
let common_api = {
  'loading': LoadingApi,
  ImgPreview
}

export default components
export { common_api } 