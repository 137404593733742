<template>
  <div class="bpy-mobile-login-warp">
    <template v-if="code_step == 1">
      <div class="content">
        <div class="title">
          登录后更精彩
          <!-- <div class="sub-title">
            <span>百元新人礼包, 登录即可领取</span>
          </div> -->
        </div>
        <div class="nav">
          <div class="nav-item" :class="{active: login_type == 1}" @click="changeType(1)">验证码登录</div>
          <div class="split"></div>
          <div class="nav-item" :class="{active: login_type == 2}" @click="changeType(2)">密码登录</div>
        </div>
        <div class="login-form" v-if="login_type == 1">
          <div class="input-box">
            <input v-model="form.account" placeholder="请输入手机号登录或注册" @input="mobileChange" @blur="validateMobile"/>
          </div>
          <!-- <div class="error-text" v-if="!account_check.status && account_check.msg">
            {{account_check.msg}}
          </div> -->
        </div>
        <div class="login-form" v-else-if="login_type == 2">
          <div class="input-box">
            <input v-model="form.account" placeholder="请输入手机号"  @input="mobileChange" @blur="validateMobile"/>
          </div>
          <!-- <div class="error-text" v-if="(!account_check.status && account_check.msg) || password_check.errCode">
            {{account_check.msg || password_check.msg}}
          </div> -->
          <div class="input-box">
            <input :type="is_hide ? 'password' : 'text'" v-model="form.password" placeholder="6-20 位字母或数字" @input="passwordChange"/>
            <img class="hide-icon" :src="hide_icon"  @click="is_hide = !is_hide" />
          </div>
          <!-- <div class="text-box">
            <div class="error-text" v-if="!password_check.status && password_check.msg && !password_check.errCode">
              {{password_check.msg}}
            </div>
            <div class="forget-text" @click="goForget">
              忘记密码
            </div>
          </div> -->
        </div>
        <div class="text-box">
          <div class="error-text" v-if="(!account_check.status && account_check.msg) || (login_type == 2 && !password_check.status && password_check.msg)">
            {{!account_check.status ? account_check.msg : (login_type == 2 && !password_check.status) ? password_check.msg : ''}}
          </div>
          <div class="forget-text" v-if="login_type == 2" @click="goForget">
            忘记密码
          </div>
        </div>
        <div class="submit-btn" v-if="login_type == 1" :class="{active: can_next}" @click="goCode">
            下一步
        </div>
        <div class="submit-btn" v-else :class="{active: can_login}" @click="pwdLogin">
            <a-spin v-if="pwd_login_loading" />
            <span>登录</span>
        </div>
      </div>
      <div class="footer">
        首次登录将自动注册 注册即同意<a :href="user_protocol">《用户服务协议》</a><a :href="privacy_protocol">《隐私政策》</a>
      </div>
    </template>
    <template v-if="code_step == 2">
      <div class="content">
        <div class="title">
          输入验证码
          <div class="info-text">
            已为 {{form.account}} 手机发送短信验证码
          </div>
        </div>
        <div class="login-form">
          <div class="code-box">
            <input ref="code" type="tel" autofocus maxlength="6" v-model="form.code" />
          </div>
          <div class="code-info">
            <div class="error-text" v-if="!code_check.status && code_check.msg">
              {{code_check.msg}}
            </div>
            <div class="code-msg">
              <div class="send-btn" v-if="!code_status" @click="sendCode">
                重新发送
              </div>
              <div class="no-send-btn" v-else>
                {{send_code_text}}
              </div>
            </div>
          </div>
          <div class="submit-btn" :class="{active: can_code_login}" @click="codeLogin">
            <a-spin v-if="code_login_loading" />
            <span>登录</span>
          </div>
          <div class="text-sub-btn" @click="goBackStep">
            <span>返回上一步</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
export default {
  name: 'MobileLogin',
  components: { },
  created () {
    document.title = '登录 - 博普云'
  },
  data () {
    return {
      login_type: 1,
      form: {
        account: '',
        code: '',
        password: ''
      },
      account_check: {
        status: false,
        msg: ''
      },
      password_check: {
        status: false,
        msg: ''
      },
      code_count: '',
      code_check: {
        status: false,
        msg: '',
      },
      code_copy: '',
      code_step: 1,
      is_hide: true,
      code_index: 0,
      code_login_loading: false,
      pwd_login_loading: false,
      ch_data: ''
    }
  },
  computed: {
    hide_icon() {
      if (this.is_hide) return '/images/icon-input-invisible-normal.svg'
      return '/images/icon-input-visible-normal.svg'
    },
    can_code_login() {
      return this.form.code && this.form.code.length === 6
    },
    can_login() {
      return this.account_check.status && this.password_check.status
    },
    can_next() {
      return this.account_check.status
    },
    can_send_code() {
      return !this.code_status && this.account_check.status
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}s 后可重新发送`
      }
      return '重新发送'
    },
  },
  methods: {
    goForget() {
      this.$router.push({
        path: 'auth_forget_mobile',
        query: this.$route.query
      })
    },
    goBackStep() {
      this.code_step = 1
      this.form.code = ''
      this.code_check = {
        status: false,
        msg: ''
      }
    },
    validateMobile() {
      if (!phone_reg.test(this.form.account)) {
        this.account_check = {
          status: false,
          msg: '请输入正确的11位手机号'
        }
      } else {
        this.account_check = {
          status: true,
          msg: ''
        }
      }
    },
    passwordChange(e) {
      if (!this.form.password) {
        this.password_check = {
          status: false,
          msg: '密码不能为空'
        }
      } else {
        this.password_check = {
          status: true,
          msg: ''
        }
      }
    },
    mobileChange(e) {
      if (e.target.value.length == 11) {
        this.validateMobile()
      } else {
        this.account_check = {
          status: false,
          msg: ''
        }
      }
      this.passwordChange()
    },
    // validatePassword() {
    //   if (!this.form.password) {
    //     this.password_check = {
    //       status: false,
    //       msg: '密码不能为空'
    //     }
    //   } else {
    //     this.password_check = {
    //       status: true,
    //       msg: ''
    //     }
    //   }
    // },
    changeType(way) {
      this.login_type = way
    },
    goCode() {
      if(!this.can_next) return
      this.sendCode(() => {
        this.code_step = 2
        this.$nextTick(() => {
          this.$refs.code.focus()
        })
      })
    },
    //验证码登录
    async codeLogin() {
      if (this.code_login_loading || !this.can_code_login) return
      this.code_login_loading = true
      let param = {
        account: this.form.account,
        code: this.form.code,
        login_type: 1,
        NO_Loading: true,
        product_id: this.from == 'yg' ? 2 : 1,
        label: this.$route.query.label || undefined,
        invite_primary_id: this.$route.query.invite_primary_id || undefined
      }
      if (this.$route.query.vid) {
        param.vid = this.$route.query.vid
      }
      let res = await AuthService.Login(param).catch(err=>{
        this.code_check = {
          status: false,
          msg: ''
        }
        if (err.errCode == 518){
          this.code_check.msg = '主账号被禁用'
        }
        if (err.errCode == 505){
          this.code_check.msg = '短信验证码错误'
        }
      })
      this.code_login_loading = false
      if (res) {
        window.SA.send('loginSubmit', {
          is_signin: !!res.is_register,
          login_method: '短信验证码登录',
          $is_success: true,
          $fail_reason: ''
        })
        if (this.redirectURI) {
          let redirectURI = decodeURIComponent(this.redirectURI)
          location.href = redirectURI
        } else if (this.from == 'zhiku'){
          location.href = this.zhiku_url
        } else if (this.from == 'yg') {
          location.href = this.yg_url
        } else {
          location.href = this.zhiku_url
        }
      }
    },
    async pwdLogin() {
      if (this.pwd_login_loading || !this.can_login) return
      this.pwd_login_loading = true
      const param = {
        account: this.form.account,
        password: this.form.password,
        login_type: 2,
        product_id: this.from == 'yg' ? 2 : 1,
        NO_Loading: true
      }
      let res = await AuthService.Login(param).catch(err=>{
        
        if (err.errCode == 518) { //主账号被禁用
          this.account_check = {
            status: false,
            errCode: err.errCode,
            msg: err.errMsg || '主账号被禁用'
          }
        }
        if (err.errCode == 1155) { //账号密码错误
          this.password_check = {
            status: false,
            errCode: err.errCode,
            msg: '手机号或密码错误'
          }
        }
        if (err.errCode == 1154) { //手机号未注册
          this.sendCode(() => {
            this.code_step = 2
            this.$nextTick(() => {
              this.$refs.code.focus()
            })
          })
          // this.account_check = {
          //   status: false,
          //   errCode: err.errCode,
          //   msg: err.errMsg || ''
          // }
          
        }
      })
      this.pwd_login_loading = false
      if (res) {
        window.SA.send('loginSubmit', {
          is_signin: !!res.is_register,
          login_method: '密码登录',
          $is_success: true,
          $fail_reason: ''
        })
        if (this.redirectURI) {
          let redirectURI = decodeURIComponent(this.redirectURI)
          location.href = redirectURI
        } else if (this.from == 'zhiku'){
          location.href = this.zhiku_url
        } else if (this.from == 'yg') {
          location.href = this.yg_url
        } else {
          location.href = this.zhiku_url
        }
      }
    },
    async sendCode(cb) {
      // if(!this.can_send_code) return
      let param = {
        account: this.form.account,
        sms_type: 'login',
        from: this.from
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '登录注册',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
        cb && cb()
      }
    }
  }
}
</script>