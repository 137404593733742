<template>
  <div class="side-bar-warp">
    <div class="side-item" v-for="(r, i) in showRoutes" :key="i" @click="goRoute(r)" :class="{active: '/' + r.path == $route.path}">
      {{r.title}}
    </div>
  </div>
</template>

<script>
const yg_path = ['bpy_myInfo', 'bpy_accountInfo', 'bpy_myOrg']
import { mapState } from 'vuex'
import {routes} from '../../index'
export default {
  name: 'SideBar',
  created () {
  },
  data () {
    return {
      routes: routes.filter(val => val.title)
    }
  },
  computed: {
    ...mapState({
      env: state => state.env,
      user_info: state => state.user_info
    }),
    showRoutes () {
      if (this.env === 'private') {
        return this.routes.filter(val => !['bpy_auth', 'bpy_product'].includes(val.path))
      }
      if (this.from === 'yg') {
        return this.routes.filter(val => yg_path.includes(val.path))
      }
      if (this.from === 'zhiku') {
        return this.routes.filter(val => {
          if (this.user_info.type == 2) {// 通过企业认证
            return val.path !== 'bpy_myOrg'
          } else {
            return !['bpy_myOrg','bpy_product'].includes(val.path)
          }
        })
      }
      return this.routes
    }
  },
  methods: {
    goRoute(route) {
      this.$router.push({
        path: route.path,
        query: this.$route.query
      })
    }
  }
}
</script>