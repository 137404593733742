<template>
   <div class="bind-page-warp login-box" :class="{'yg-warp': from == 'yg'}">
    <div class="login-logo">
      <div class="zhiku-logo">
        <img src="/images/img_logo_bopuyun.png" />
        <p v-if="from == 'zhiku'">制药人专业知识分享平台</p>
      </div>
      <!-- <img v-if="from == 'yg'" class="yg-logo" src="/images/img-logo-yunguan.svg" />
      <div class="zhiku-logo"  v-else>
        <img src="/images/img-logo-zhiku.svg" />
        <p>制药人专业知识分享平台</p>
      </div> -->
    </div>
    <div class="page-title">
      <h1>绑定手机号</h1>
      <p>绑定手绑定后，可以使用手机登录机号</p>
    </div>
    <div class="login-form">
      <a-form-model ref="bindForm" :model="bindForm" :rules="ruleInline">
        <a-form-model-item label="" prop="account">
          <a-input v-model="bindForm.account" placeholder="请输入手机号" autocomplete="new-password" @change="mobileChange"/>
        </a-form-model-item>
        <a-form-model-item class="code-box" label="" prop="code">
          <a-input class="code-input" v-model="bindForm.code" placeholder="短信验证码" @change="smsChange">
            <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode">{{send_code_text}}</div>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="btn-box">
      <div class="login-btn">
        <a-button type="primary" size="large" @click="handleSubmit('bindForm')">
          下一步
        </a-button>
      </div>
      <!-- <div class="sub-btn" @click="cancelBind">
        稍后再说
      </div> -->
    </div>
    <a-modal title="此手机号已注册"
      centered
      width="560px"
      v-model="bind_show"
      :maskClosable="false"
      :keyboard="false"
      cancelText="取消"
      okText="确认绑定"
      @ok="bindConfirm"
      >
        <div>手机号 {{bind_info.account}} 已注册账号「{{bind_info.nickname}}」，是否将当前微信号（{{bind_info.third_nickname}}）绑定至账号「{{bind_info.nickname}}」，绑定后可以使用手机号或微信登录。</div>
    </a-modal>
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
import { mapState, mapActions } from 'vuex'
export default {
  name: 'SetPassword',
  created() {
    
  },
  mounted() {

  },
  computed: {
    ...mapState({
      zhiku_url: state => state.app_domain.zhiku_url,
      mall_url: state => state.app_domain.mall_url
    }),
    from_zhiku() {
      return this.$route.query.from == 'zhiku' || this.$route.query.f == 'zhiku'
    }
  },
  data () {
    let validateMobile = async(rule, value, callback) => {
      this.mobile_check = false
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!new RegExp(phone_reg).test(value)){
        callback(new Error('请输入正确的11位手机号'));
      } else {
        let res = await AuthService.checkBindStatus({
          account: this.bindForm.account,
          NO_TIPS: true,
          return_status: 1
        })
        if (res.status === 1) {
          this.bind_info = res
          this.mobile_check = true
          callback()
          // if (res.status == 3) {
          //   callback(new Error('此手机号已被绑定，请换一个手机号'))
          // } else if (res.status) {
              // this.mobile_check = true
              // callback()
          // }
        } else {
          callback('此手机号已被绑定，请换一个手机号，或联系客服(wechat: BPYD2021)')
        }
      }
    }
    const validateMsgVerification = (rule, value, callback) => {
      if (new RegExp(/^$| /).test(this.bindForm.code)) {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 535) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    return {
      is_submitting: false,
      failure_code: '', // 登录错误码,
      code_count: '',
      bindForm: {
        account: '',
        code: '',
      },
      mobile_check: false,
      ruleInline: {
        account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification, trigger: 'blur' }
        ]
      },
      centerDialogVisible: false,
      bind_show: false,
      bind_info: {}
    }
  },
  watch: {
    // 'bindForm': {
    //   handler() {
    //     this.failure_code = false;
    //     if (this.bindForm.account && new RegExp(phone_reg).test(this.bindForm.account)) {
    //       this.phone_is_validated = true
    //     }
    //   },
    //   deep: true
    // }  
  },
  methods: {
    skipPage(url) {
      const route = {
        path: url,
        query: this.$route.query
      };
      this.$router.push(route)
    },
    //获取验证码倒计时
    async sendCode() {
      if(!this.can_send_code) return
      let param = {
        account: this.bindForm.account,
        sms_type: 'modify_account'
      }
      window.SA.send('getMessageCodeClick', {
        service_type: '验证手机号',
        $is_success: true,
        $fail_reason: ''
      })
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    mobileChange(event) {
      let value = event.target.value
      this.failure_code = false
      this.mobile_check = false
      // if (value && new RegExp(phone_reg).test(value)) {
      //   this.mobile_check = true
      // }
      if (value.length == 11) {
        this.$refs.bindForm.validate()
      }
    },
    smsChange() {
      this.failure_code = false
    },
    // //获取验证码倒计时
    // getMsgVerification() {
    //   const countDown = (duration) => {
    //     if (!duration) {
    //       this.get_code_tips = "获取验证码";
    //       return
    //     };
    //     this.get_code_tips = `${duration}S`;
    //     setTimeout(() => {
    //       countDown(--duration)
    //     }, 1000)
    //   }
    //   this.get_code_tips = '获取中...';
    //   AuthService.getPhoneBindVerification({
    //     account: this.bindForm.account,
    //   }, (res) => {
    //     countDown(60);
    //   }, (err) => {
    //     if (err && err.errCode === '503') {
    //       this.get_code_tips = '获取验证码';
    //       this.failure_code = '503';
    //     }
    //   })
      
    // },
    setInputError(name) {
      this.$refs[name].validate((valid) => {})
    },
    handleSubmit(name) {
      if (this.is_submitting) return
      this.$refs[name].validate(async (valid) => {  
        if (valid) {
          const param = {
            account: this.bindForm.account,
            code: this.bindForm.code,
            type: 1,
            label: this.$route.query.label || undefined,
            invite_primary_id: this.$route.query.invite_primary_id || undefined,
            product_id: this.from == 'yg' ? 2 : 1
          }
          if (this.bind_info.status == 1) {
            this.is_submitting = true
            let res = await AuthService.bpyBind(param).catch(err => {
              console.log(err)
              this.failure_code = err.errCode
              this.$nextTick(() => {
                this.$refs.bindForm.validateField('code')
              })
            })
            console.log(res)
            this.is_submitting = false
            if (res) {
              this.$message.success('绑定成功')
              this.goNext()
            }
          }
          if (this.bind_info.status == 2) {
            this.bind_show = true
          }
        }
      })
    },
    async bindConfirm() {
      if (this.is_submitting) return
      const param = {
        account: this.bindForm.account,
        code: this.bindForm.code,
        type: 1,
        label: this.$route.query.label || undefined,
        invite_primary_id: this.$route.query.invite_primary_id || undefined,
        product_id: this.from == 'yg' ? 2 : 1
      }
      this.is_submitting = true
      let res = await AuthService.bpyBind(param).catch(err => {
        this.failure_code = err.errCode
        this.$nextTick(() => {
          this.$refs.bindForm.validateField('code')
        })
      })
      this.bind_show = false
      this.is_submitting = false
      if (res) {
        this.$message.success('绑定成功')
        this.goNext()
      }
    },
    //暂不绑定
    async cancelBind() {
      if (this.is_submitting) return
      const param = {
        type: 2,
        label: this.$route.query.label || undefined,
        invite_primary_id: this.$route.query.invite_primary_id || undefined,
        product_id: this.from == 'yg' ? 2 : 1
      }
      this.is_submitting = true
      let res = await AuthService.bpyBind(param)
      this.is_submitting = false
      if (res) {
        this.goNext()
      }
    },
    //关闭模态框
    closeModal(){
      this.bind_show = false
    },
  },
  computed: {
    ...mapState({
      zhiku_url: state => state.app_domain.zhiku_url,
      mall_url: state => state.app_domain.mall_url,
      yg_url: state => state.app_domain.yg_url
    }),
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    },
  }
}
</script>
