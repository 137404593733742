<template>
  <div class="bpy-body-warp">
    <Header></Header>
    <div class="bpy-container">
      <template v-if="page_type == 1">
        <SideBar class="fiexd"></SideBar>
        <div class="bpy-content">
          <router-view />
        </div>
      </template>
      <template v-else>
        <router-view />
      </template>
    </div>
    <Footer v-if="env != 'private'"></Footer>
  </div>
</template>

<script>

import Header from './components/Header'
import SideBar from './components/SideBar'
import Footer from './components/Footer'
import {routes} from './index'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Body',
  components: { Header, SideBar, Footer },
  created () {
    document.title = '个人账号中心 - 博普云'
    if (this.$route.path !== '/bpy_account/change') {
      this.initUserInfo()
    }
  },
  data () {
    return {
    }
  },
  computed: {
    page_type() {
      let pages = routes.filter(val => val.title).map(val => val.path)
      if (pages.includes(this.$route.path.replace('/', ''))) {
        return 1
      }
      return 2
    },
    ...mapState({
      env: state => state.env,
    })
  },
  methods: {
    ...mapActions([
      'initUserInfo'
    ])
  }
}
</script>