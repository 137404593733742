<template>
  <div class="loading-warp" v-show="loading_status">
    <div class="loading-mask"></div>
    <a-spin />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name:'Loading',
  created() {
  },
  mounted() {
    
  },
  beforeDestroy () {
    
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState({
      'loading_status': state => state.loading_status
    })
  }
}
</script>