import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from '@/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: pages
});
router.beforeEach((to, from, next) => {
  // ...
  let list = ['/auth_login', '/auth_forget']
  let mobile_list = ['/auth_login_mobile', '/auth_forget_mobile']
  if (list.includes(to.path) && Util.isMobile()) {
    next({ path: to.path + '_mobile' , query: to.query})
  } else if (mobile_list.includes(to.path) && !Util.isMobile()) {
    next({ path: to.path.replace('_mobile', ''), query: to.query })
  } else {
    next()
  }
})
export default router
