// /**
// * @func: loading api
// * @desc: loading服务调用
// * @author sean
// * @version 1.0.0
// */

import Vue from 'vue'
import Loading from './Loading.vue'
let indicator = null
let delay = null
let size = 'default'
let spinning = true
let tip = null
let warpperClassName = null
// let fullInstance = null
let appendTo = null

const newInstance = (options) => {
  const _options = {
    indicator: options.indicator || indicator,
    delay: options.delay || delay,
    size: options.size || size,
    spinning: options.spinning || spinning,
    tip: options.tip || tip,
    warpperClassName: options.warpperClassName || warpperClassName
  }
  const Instance = new Vue({
    created() {
      
    },
    data() {
      return {
      }
    },
    beforeDestroy() {
      
    },
    render (h) {
      return h(Loading, {
        data() {
          return {
            ..._options
          }
        },
        on: {
        },
      })
    },
    methods: {
      destroy () {
        this.$destroy()
        document.body.removeChild(this.$el)
      }
    }
  })
  Instance.vm = Instance.$mount()
  if (options.full) {
    // fullInstance = Instance.vm
    document.body.appendChild(Instance.vm.$el)
  } else {
    appendTo = options.appendTo || appendTo
    if (appendTo) {
      document.getElementById(appendTo).appendChild(Instance.vm.$el)
    } else {
      document.body.appendChild(Instance.vm.$el)
    }
  }
  return Instance.vm
}
newInstance.close = function() {
  this.destroy()
}

const loadingApi = {
  open: newInstance,
  full: (options) => {
    return open({...options, full: true })
  },
  set: (options) => {
    return open({...options, full: false})

  }
}


export default loadingApi