import View from './index.vue'

export default [{
  title: '企业邀请',
  path: '/bpy_invite',
  component: View
},{
  title: '企业邀请',
  path: '/bpy_invite_mobile',
  component: View
}]