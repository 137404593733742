import { mapActions, mapState } from 'vuex'
var mixin = {
  data() {
    return {
      is_pc: !Util.isMobile(),
      is_wx: !Util.isWeiXin(),
      privacy_protocol: 'https://zhiku.bopuyun.com/p/86216834163',
      user_protocol: 'https://zhiku.bopuyun.com/p/81848483943',
      real_protocol: 'https://zhiku.bopuyun.com/p/113853720375',
      about_us_protocol: 'https://zhiku.bopuyun.com/p/81873625527',
      // contact_protocol: 'https://zhiku.bopuyun.com/p/81848483943',
    }
  },
  methods: {
    ...mapActions(['initUserInfo']),
    goNext() {
      if (this.redirectURI) {
        let redirectURI = this.redirectURI
        location.href = redirectURI
      } else if (this.from == 'zhiku'){
        location.href = this.this.zhiku_url
      } else if (this.from == 'yg') {
        location.href = this.yg_url
      } else {
        location.href = '/'
      }
    },
  },
  computed: {
    ...mapState({
      user_info: state => state.user_info,
      zhiku_url: state => state.app_domain.zhiku_url,
      yg_url: state => state.app_domain.yg_url,
    }),
    from() {
      return this.$route.query.f || this.$route.query.from || 'zhiku'
    },
    redirectURI() {
      return this.$route.query.redirectURI || this.$route.query.redirect_url
    },
  },
}
export default mixin