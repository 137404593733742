import dayjs from 'dayjs'
import Util from './util'
const filters = {
  day: (date, formatter) => {
    formatter = formatter || 'YYYY-MM-DD HH:mm:ss'
    return dayjs(date).format(formatter)
  },
  mobileMask: (value) => {
    value = value || ''
    return value.substring(0, 3) +'****'+ value.substr(value.length - 4)
  },
}

export default filters