<template>
  <div class="common-zoomin-preview-warp">
    <!-- <div class="head-title">
      {{name}}
    </div> -->
    <div class="preview-content" >
      <div class="left-btn index-btn" v-if="index > 0 && urls.length > 1" @click="index--">
        <a-icon type="left-square" theme="filled" />
      </div>
      <img ref="imgView" v-dragMoveScale class="preview-img" :class="is_zoom ? 'zoomin' : 'default'" :draggable="false" :src="current_url"/>
      <div class="right-btn index-btn" v-if="index < urls.length - 1 && urls.length > 1" @click="index++">
        <a-icon type="right-square" theme="filled" />
      </div>
    </div>
    <div class="close-btn" @click="show = false">
      <a-icon type="close" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'zoominPreview',
  components: {  },
  props: [ 'visible', 'name', 'urls', 'url_index'],
  data () {
    return {
      is_zoom: false
    }
  },
  computed : {
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    index: {
      get() {
        return this.url_index
      },
      set(val) {
        if (this.$refs.imgView) {
          this.$refs.imgView.style.transform = ''
          this.$refs.imgView.style.top = 0
          this.$refs.imgView.style.left = 0
        }
        this.$emit('update:url_index', val)
      }
    },
    current_url() {
      let index = this.url_index > this.urls.length - 1 ? 0 : this.url_index
      return this.urls[index]
    }
  },
  created () {
    
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    doClose() {
      this.show = false
    },
  },
  watch: {
    show(val) {
      if(!val) {
        this.$emit('close')
      }
    }
  },
  filters: {
     
  }
}
</script>