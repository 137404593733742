import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import { Upload2Oss } from './assets/upload.js'
import 'ant-design-vue/dist/antd.css'
import BpyIcon from '@bpyd/icons'
import dayjs from 'dayjs'

import {normalComponents, common_api} from '@/components'
Vue.config.productionTip = false

Vue.use(BpyIcon)
Vue.use(Antd)

import '@/styles/index.less'
import '@/components/index.less'
import '@/styles/ant-theme.less'

import Http from '@/assets/http'
import Collector from '@/assets/collector'
import Util from '@/assets/util'
import directives from '@/assets/directives.js'
import {getStorage, setStorage, removeStorage, clearStorage} from '@/assets/storage'

let _ = require('lodash')
let Cookie = require('js-cookie')

window._ = _
window.Http = { ...Http }
window.Collector = Collector
window.getStorage = getStorage
window.setStorage = setStorage
window.removeStorage = removeStorage
window.clearStorage = clearStorage
window.Util = Util
window.$Cookie = Cookie
window.Upload2Oss = Upload2Oss
window.$message = message
window.dayjs = dayjs
// window.$message = message

//全局混入
import GlobalMixin from '@/assets/global_mixin'
Vue.mixin(GlobalMixin)

// 注册全局组件
Object.keys(normalComponents).forEach( (key) => {
  Vue.component(key, normalComponents[key])
})

// 注册全局组件服务
Object.keys(common_api).forEach( (key) => {
  Vue.prototype[`$${key}`] = common_api[key]
})

// service全局注入
import ServiceManager from './service/index.js'
Object.keys(ServiceManager).forEach( (key) => {
  window[key] = ServiceManager[key]
})

//全局注册过滤器
import filters from '@/assets/filters.js'
Object.keys(filters).forEach( (key) => {
  Vue.filter(key, filters[key])
})

// 注册全局指令
Object.keys(directives).forEach( (key) => {
  Vue.directive(key, directives[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
