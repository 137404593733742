<template>
  <div class="header-warp">
    <h1 class="title">个人账号中心</h1>
    <div class="right-box"  v-if="showUserInfo">
      <a-dropdown placement="bottomCenter">
        <div class="user-box">
          <div class="user-avatar">
            <img :src="user_info.avatar" />
          </div>
          <img class="arrow-icon" src="/images/icon-arrow-dropdown.svg" alt="" srcset="">
        </div>
        <a-menu slot="overlay" @click="logout">
          <a-menu-item :name="1" >退出登录</a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Header',
  created () {
  },
  data () {
    return {
    }
  },
  computed: {
    showUserInfo () {
      return this.$route.path !== '/bpy_account/change'
    }
  },
  methods: {
    async logout() {
      await AuthService.Logout()
      this.$router.push({
        path: 'auth_login',
        query: {
          f: this.$route.query.f || this.$route.query.from,
          redirect_url: location.href
        }
      })
    }
  }
}
</script>