<template>
  <div class="login-page-warp login-box" :class="{'yg-warp': from == 'yg'}">
    <div class="login-logo">
      <!-- <img class="yg-logo" src="/images/img_logo_bopuyun.png" /> -->
      <div class="zhiku-logo">
        <img src="/images/img_logo_bopuyun.png" />
        <p v-if="from == 'zhiku'">制药人专业知识分享平台</p>
      </div>
    </div>
    <div class="method-select">
      <span v-if="env != 'private'" :class="{'active': login_type == 1}" @click="changeWay(1)">
        验证码登录
        <div class="split-line"></div>
      </span>
      <span :class="{'active': login_type == 2}" @click="changeWay(2)">密码登录</span>
    </div>
    <div class="login-form">
      <a-form-model ref="loginForm" :model="loginForm" :rules="rules">
        <a-form-model-item label="" prop="account" >
          <a-input v-model="loginForm.account" @keyup.enter.stop="submitForm('loginForm')" placeholder="请输入手机号" autocomplete @change="mobileChange"/>
        </a-form-model-item>
        <a-form-model-item class="code-box" label="" prop="code" v-if="login_type == 1">
          <a-input class="code-input" v-model="loginForm.code" placeholder="短信验证码"  @change="smsChange" @keyup.enter.stop="submitForm('loginForm')">
            <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode">{{send_code_text}}</div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="" prop="password" v-else>
          <a-input v-model="loginForm.password"  @keyup.enter.stop="submitForm('loginForm')" type="password" autocomplete placeholder="密码" @change="passwordChange" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="btn-box">
      <div class="login-btn">
        <a-button type="primary" size="large" @click="submitForm('loginForm')">
          登录
        </a-button>
      </div>
      <div v-if="env != 'private'" class="sub-btn" style="margin-bottom: 0;">
        <div class="protocol-check">
          已阅读并同意<a class="protocol" target="_blank" :href="user_protocol">《用户服务协议》</a><a class="protocol" target="_blank" :href="privacy_protocol">《隐私政策》</a>
        </div>
        <div class="text-btn forget-btn" @click="goForget">
          忘记密码
        </div>
      </div>
      <!-- <div class="text-btn register-btn" @click="goRegister">
        免费注册
      </div> -->
    </div>
    <!-- <div class="wechat_login" v-if="from != 'yg' && is_pc && env != 'private'">
      <p class="tip">其它登录方式</p>
      <div class="third_way">
        <img class="icon" src="/images/icon-wechat.png" @click="wechatLogin" />
      </div>
    </div> -->
  </div>
</template>

<script>
const phone_reg = /^[1][0-90]{10}$/
import { mapActions, mapState } from 'vuex'
export default {
  name:'login',
  created() {
    document.title = '登录/注册'
    document.keyword = '博普云达,博普云,博普智库,博普云管,智库,云管,生物医药,制药技术,GMP,研发,制造,政策法规,质量标准,论文,模板,资讯,课程,标准化'
    document.description = '以互联网为基础的标准化制药过程专业解决方案与服务平台，「博普智库」「博普云展」与「博普云管」三大产品构建了「博普云」平台的基础'
  },
  created () {
    if (this.env == 'private') {
      this.login_type = 2
    }
  },
  beforeDestroy () {
    
  },
  data() {
    let validateMobile = async(rule, value, callback) => {
      if (this.login_type == 1) { //短信登录校验
        this.mobile_check = false
        if (value === '') {
          callback(new Error('请输入手机号'))
        } else if (!new RegExp(phone_reg).test(value)){
          callback(new Error('请输入正确的11位手机号'))
        } else {
          this.mobile_check = true
          callback()
        }
      }
      if (this.login_type == 2) { //密码登录校验
        if (value === '') {
          callback(new Error('请输入手机号'))
        } else if (!new RegExp(phone_reg).test(value)){
          callback(new Error('请输入正确的11位手机号'))
        } 
        // else if (this.failure_code && [1155, 1154, 518].includes(this.failure_code)) {
        //   callback(new Error(' '))
        // } 
        else {
          // let res = await AuthService.isRegistered({
          //   account: this.loginForm.account,
          //   NO_TIPS: true
          // })
          // if (res) {
          //   if (res.is_registered) {
          //     callback()
          //   } else {
          //     callback(new Error('该手机号还未注册，请使用验证码登录'))
          //   }
          // } else {
          //   callback()
          // }
          callback()
        }
      }

        // AuthService.checkPhoneExist({
        //   account: this.formInline.account,
        //   NO_TIPS: true
        // }, (res) => {
        //   callback(new Error('该手机号码未注册'));
        // }, (err) => {
        //   callback();
        // })
    }
    let validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else if (this.failure_code && [1155, 1154, 518, 535].includes(this.failure_code)) {
        let text = this.failure_msg
        if (this.failure_code == 1155) {
          text = '账号密码错误'
        }
        if (this.failure_code == 1154) {
          text = '手机号未注册'
        }
        if (this.failure_code == 518) {
          text = '主账号被禁用'
        }
        callback(new Error(text))
      } else {
        callback()
      }
    }
    let validateCode = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 505) {
        callback(new Error('短信验证码错误'))
      } else {
        callback()
      }
    }
    return {
      login_type: 1, //1 短信验证码 2密码
      loginForm: {
        account: '',
        password: '',
        code: '',
      },
      rules: {
        account: [{ validator: validateMobile, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        code: [{ validator: validateCode, trigger: 'change' }]
      },
      mobile_check: false,
      failure_code: '',
      failure_msg: '',
      code_count: 0
    }
  },
  methods: {
    passwordChange() {
      this.failure_code = false
      this.failure_msg = false
    },
    smsChange() {
      this.failure_code = false
      this.failure_msg = false
    },
    changeWay(key) {
      this.$refs.loginForm.clearValidate()
      this.$nextTick(() => {
        this.login_type = key || 2
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let param = Object.assign({...this.loginForm}, {login_type: this.login_type})
          param.login_type == 1 && delete param.password
          param.login_type == 2 && delete param.code
          param.product_id = this.from == 'yg' ? 2 : 1
          if(param.login_type == 1) {
            param.label = this.$route.query.label || undefined
            param.invite_primary_id = this.$route.query.invite_primary_id || undefined
          }
          if (this.$route.query.vid) {
            param.vid = this.$route.query.vid
          }
          let res = await AuthService.Login(param).catch(err=>{
            this.failure_code = err.errCode
            this.failure_msg = err.errMsg
            if (err.errCode == 1154) {
              this.login_type = 1
            }
            this.$nextTick(() => {
              this.$refs.loginForm.validate()
            })
          })
          if (res) {
            window.SA.send('loginSubmit', {
              is_signin: !!res.is_register,
              login_method: param.login_type == 1 ? '短信验证码登录' : '密码登录',
              $is_success: true,
              $fail_reason: ''
            })
            if (this.redirectURI) {
              let redirectURI = decodeURIComponent(this.redirectURI)
              location.href = redirectURI
            } else if (this.from == 'zhiku'){
              location.href = this.zhiku_url
            } else if (this.from == 'yg') {
              location.href = this.yg_url
            } else {
              location.href = '/'
            }
          }
        } else {
          return false;
        }
      })
    },
    goForget() {
      let param = {
        path: '/auth_forget',
      }
      param.query = this.$route.query
      this.$router.push(param)
    },
    mobileChange(event) {
      let value = event.target.value
      this.failure_code = false
      this.failure_msg = false
      this.mobile_check = false
      if (value && new RegExp(phone_reg).test(value)) {
        this.mobile_check = true
      }
      // if (value.length == 11) {
      //   this.$refs.loginForm.validate()
      // }
    },
    async sendCode() {
      if(!this.can_send_code) return
      window.SA.send('getMessageCodeClick', {
        service_type: '登录注册',
        $is_success: true,
        $fail_reason: ''
      })
      let param = {
        account: this.loginForm.account,
        sms_type: 'login',
        from: this.from
      }
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    wechatLogin(){
      //默认智库
      let redirect_uri = this.zhiku_url || location.origin
      if (this.redirectURI) {
        redirect_uri = this.redirectURI
      } else if (this.from == 'zhiku') {
        redirect_uri = this.zhiku_url
      } else if (this.from == 'yg') {
        redirect_uri = this.yg_url
      }
      let loginQuery = {}
      this.from && (loginQuery.f = this.from)
      this.$route.query.label && (loginQuery.label = this.$route.query.label)
      this.$route.query.invite_primary_id && (loginQuery.invite_primary_id = this.$route.query.invite_primary_id)
      let login_param_arr = Object.keys(loginQuery).reduce((res, key) => {
        loginQuery[key] && res.push(`${key}=${loginQuery[key]}`)
        return res
      }, [])
      if (redirect_uri.indexOf('/bpy_accountInfo')) {
        redirect_uri = redirect_uri.replace('/bpy_accountInfo', '/')
      }
      login_param_arr.push(`redirectURI=${encodeURIComponent(decodeURIComponent(redirect_uri))}`)
      location.href = `/getAuthUrl?${login_param_arr.join('&')}`
    }
  },
  computed: {
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    },
    ...mapState({
      env: state => state.env,
    }),
  },
}
</script>