const Util = {}

/**
  * @func: copy
  * @desc: 复制链接
  * @param url     {String}    url
  * @author janber
  * @version 1.0.0
  */
Util.copy2Clipboard = function(url) {
  let input = document.createElement('input')    // 直接构建input
  input.value = url   // 设置内容
  document.body.appendChild(input)        // 添加临时实例
  input.setAttribute('readonly', 'readonly')
  input.select()      // 选择实例内容
  input.setSelectionRange(0, input.value.length)
  document.execCommand('Copy')     // 执行复制
  let text = document.execCommand('Copy')
  if (text){
    document.body.removeChild(input)  // 删除临时实例
  }
}

/**
  * @func: selectFile
  * @desc: 选择文件
  * @param param {file_type: '文件类型匹配(jpg-png)', max_size: '文件大小限制(M)', single: '是否单选'}
  * @param cb 上传成功回调函数
  * @returns void
  * @author janber
  * @version 1.0.0
  */
 Util.selectFile =(param, cb) => {
  const $upload = document.querySelector('#uploadFileInput')
  $upload.removeAttribute('accept')
  $upload.removeAttribute('multiple')
  let _default = {
    file_type: 'jpg-jpeg-png-pdf-doc-docx-xls-xlsx-ppt-pptx-dwg-zip-rar',
    max_size: 5,
    single: true
  }
  let params = Object.assign({}, _default, param)
  let file_type = params.file_type.split('-')
  // let accept_str = []
  const file_MIME_map = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    zip: 'application/zip',
    rar: 'application/x-rar-compressed',
    txt: 'text/plain',
    wpd: 'application/vnd.ms-works',
    pdf: 'application/pdf',
    dwg: 'application/x-autocad',
    dws: 'application/x-autocad',
    dwt: 'application/x-autocad',
    dxf: 'application/x-autocad',
    zip: 'application/x-gzip',
    rar: 'application/octet-stream'
  }
  // accept_str = file_type.map(type => file_MIME_map[type])
  
  // $upload.setAttribute('accept', accept_str.join(','))

  !params.single && ($upload.setAttribute('multiple', 'multiple'))
  
  $upload.value = ''
  $upload.onchange = function(e) {
    if (e.target.files.length == 0) {
      return
    }
    let nosupport_file = []
    let files = Array.prototype.filter.call(e.target.files, val => {
      let arr = val.name.split('.')
      let type = arr[arr.length - 1].toLowerCase()
      if (file_type.indexOf(type) <= -1) {
        nosupport_file.push(val.name)
      }
      val.file_type = type
      return file_type.indexOf(type) > -1
    })
    if (nosupport_file.length > 0) {
      $message.error(`${nosupport_file.join('、')} ，文件格式不支持`)
    }
    cb && cb(files)
  }
  $upload.click()
}

Util.randomString = (len) => {
  len = len || 32;
  var $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
  var maxPos = $chars.length;
  var str = '';
  for (let i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
　return str
}

Util.dataURL2File = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {type:mime})
}

Util.getParents = (id, data, plist = []) => {
  let node = null
  for(let i = 0, l = data.length; i < l; i++) {
    let list = [...plist]
    list.push(data[i])
    if(id == data[i].id) {
      return list
    }
    if(data[i].children && data[i].children.length > 0) {
      node = Util.getParents(id, data[i].children, list) || node
    }
  }
  return node
}

Util.getNameOrSuffix = (str, suffix = false) => {
  let splits = str.split('.')
  if (splits.length > 1) {
    if(suffix) {
      return splits[splits.length - 1]
    } else {
      return str.replace('.' + splits[splits.length - 1], '')
    }
  }
  return str
}
/**
  * @func: validatePc
  * @desc: 校验pc端或者移动
  * @param 
  * @return Boolean
  * @author niling
  * @version 
  */
 Util.validatePc = () => {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  var uas = navigator.userAgent.toLowerCase();
  var wx = uas.match(/MicroMessenger/i)=="micromessenger"
  if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || wx) {
      return false	// 移动端
  } else {
      return true		// pc
  }
}

Util.isMobile = () => {
  if( navigator.userAgent.match(/Android/i)
		|| navigator.userAgent.match(/webOS/i)
		|| navigator.userAgent.match(/iPhone/i)
		|| navigator.userAgent.match(/iPad/i)
		|| navigator.userAgent.match(/iPod/i)
		|| navigator.userAgent.match(/BlackBerry/i)
		|| navigator.userAgent.match(/Windows Phone/i)
	)return true
	return false
}

Util.isWeiXin = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

Util.copy2Clipboard = function(url) {
  let input = document.createElement('input')    // 直接构建input
  input.value = url   // 设置内容
  document.body.appendChild(input)        // 添加临时实例
  input.setAttribute('readonly', 'readonly')
  input.select()      // 选择实例内容
  input.setSelectionRange(0, input.value.length)
  document.execCommand('Copy')     // 执行复制
  let text = document.execCommand('Copy')
  if (text){
    document.body.removeChild(input)  // 删除临时实例
  }
}

Util.urlJoinQuery = (url, query) => {
  if (!query) {
    return url
  }
  if (url.indexOf('?') <= -1) {
    url += '?'
  } else {
    url += '&'
  }
  if (Object.prototype.toString.call(query) == '[object Object]') {
    for (var key in query) {
      url += `${key}=${query[key]}&`
    }
    url = url.substr(0, url.length - 1)
  } else if(Object.prototype.toString.call(query) == '[object String]'){
    if (query.indexOf('?') == 0) {
      query = query.substr(1, query.length)
    }
    url += query
  }
  return url
}

/**
* @func: validatePwd
* @desc: 校验密码强度（6~20位，数字、大写字母和小写字母两种以上组合）
* @param value     {Str}   待验证密码串
* @returns Boolean
* @author janber
* @version 1.0.0
*/
Util.validatePwd = (value) => {
  var regex = /(?!^[0-9]{6,20}$)(?!^[a-z]{6,20}$)(?!^[A-Z]{6,20}$)^[0-9A-Za-z]{6,20}$/
  return regex.test(value)
}

Util.encryptData = (data) => {
  const encryptKey = window.CryptoJS.enc.Utf8.parse('sRGARyYB5UnWSgKp')
  let encrypted = window.CryptoJS.AES.encrypt(JSON.stringify(data), encryptKey, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7 })
  let _data = encrypted.toString()
  return { k: '', d: _data }
}

Util.decryptData = (data) => {
  const encryptKey = window.CryptoJS.enc.Utf8.parse('sRGARyYB5UnWSgKp')
  let _data = window.CryptoJS.AES.decrypt(data.d, encryptKey, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7}).toString(window.CryptoJS.enc.Utf8)
  return JSON.parse(_data)
}

// 数字加密
Util.numberEncode = (number) => {
  number = Number(number)
  return (number + 12763) * 6285396 + 57231
}

// 数字解密
Util.numberDecode = (number) => {
  number = Number(number)
  if (number < numberEncode(1)) {
    return number
  } else {
    return (number - 57231) / 6285396 - 12763
  }
}

export default Util