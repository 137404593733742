const clickoutside = {
  bind (el, binding, vnode) {
    function documentHandler (e) {
      if (el.contains(e.target)) {
        return false
      }
      if (binding.expression) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  update () {

  },
  unbind (el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
}

const dragMoveScale = {
  bind (el, binding, vnode) {
      let scale = 1
      // tableBox.draggable
      // let tableScrollWidth = tableScroll.style.width  //滚动可见宽度
      // let tableBoxWidth = tableBox.style.width  //内容宽度
      function mouseDownHandler (ev) {
        let oEvent = ev //ev 成立时 oEvent = event，否则 oEvent = ev , 事件对象 兼容处理
        let mousePlaceX =  oEvent.clientX  //点击时当前鼠标的位置
        let mousePlaceY =  oEvent.clientY  //点击时当前鼠标的位置
        //鼠标移动的时候
        document.onmousemove = function (ev){
          let oEvent = ev  //当前的事件对象
          let distanceX = oEvent.clientX
          let distanceY = oEvent.clientY
          let actualX = distanceX - mousePlaceX //拖动的距离X
          let actualY = distanceY - mousePlaceY //拖动的距离Y
          let left = el.style.left.replace('px', '') || 0
          let top = el.style.top.replace('px', '') || 0
          left = left * 1 + actualX
          top = top * 1 + actualY
          el.style.left = left + 'px'
          el.style.top = top + 'px'
          mousePlaceX = distanceX
          mousePlaceY = distanceY
        }
        document.onmouseup = function(ev) {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
      function mousewheelHandler (ev) {
        let delta = ev.wheelDelta || - ev.detail
        if (delta <= 0) {
          scale *= 1.1
          if (scale >= 2) {
            scale = 2
          }
        } else {
          scale /= 1.1
          if (scale <= 1) {
            scale = 1
          }
        }
        el.style.transform = `scale(${scale})`
      }
      el.__dragMoveScale__ = mouseDownHandler
      el.addEventListener('mousedown', mouseDownHandler)
      el.addEventListener('mousewheel', mousewheelHandler)
  },
  update () {

  },
  unbind (el, binding) {
      el.removeEventListener('mousedown', el.__dragMoveScale__)
      delete el.__dragMoveScale__
  }
}

export default { clickoutside, dragMoveScale }