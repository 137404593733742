/*
 * @name 全局页面统计
 * @auther janber
 * @created 19-09-24
 */

import login from './login/index.js'
import body from './body/index.js'
import forget from './forget/index.js'
import bindPhone from './bindPhone/index.js'
import bpyBindPhone from './bpyBindPhone/index.js'
import mobileLogin from './mobile_login/index.js'
import mobileForget from './mobile_forget/index.js'
import invite from './invite/index.js'

export default [ body, login, bindPhone, forget, bpyBindPhone, ...mobileLogin, mobileForget, ...invite ]