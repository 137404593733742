/*
 * @name http请求封装
 * @auther sean
 * @created 21-02-23
 */

import devTools from './devTools.js'
import QUERY_API from '@serve_config'
import axios from 'axios'
import store from '@/store'
import { message } from 'ant-design-vue'
import { getStorage, removeStorage } from './storage'
import router from '@/router'

const http = {
  /**
  * @func: query
  * @desc: 通用请求
  * @param url     {String}   请求路径
  * @param api     {String}   请求服务器地址
  * @param param   {Object}   请求参数(api, url, ...otherParams) 
  * @returns promise
  * @author sean
  * @version 1.0.0
  */
  query: function(param) {
    const _param = Object.assign({}, {...param})
    delete param.url
    delete param.api
    delete param.NO_TIPS
    delete param.NO_Loading
    // 判断参数完整性
    if (!_param.url) {
      devTools.logError('need params  @url')
      return
    }
    let no_tips = false;
    let no_loading = false;

    // 数据返回后全局提升开关
    if (_param.NO_TIPS) {
      no_tips = true;
    }

    // 打开全局Loading
    if (_param.NO_Loading) {
      no_loading = true
    }

    // 拼接请求地址
    const url = `${QUERY_API[_param.api || 'base']}${_param.url}`

    if (!no_loading) {
      store.dispatch('showLoading')
    }
    // promise组装axios实现拦截器修改后的响应数据 能正确返回
    // 生成请求唯一ID
    const uuidObj = Util.encryptData({
      d: dayjs().unix()
    })
    const headers = {
      client: 'WEB_PC',
      clientCredential: Util.numberEncode(dayjs().unix()).toString(36)
    }
    const _api = _param.api || 'base'
    if (_api === 'base') {
      headers['client-unique-id'] = uuidObj.d
    }
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: url,
        headers: headers,
        data: param,
        withCredentials: true,
        responseType: 'json'
      }).then(response => {
        const res = response.data || { message: '操作失败' };
        if (!no_loading) {
          store.dispatch('hideLoading')
        }
        if (res.errCode === 1014 && location.pathname !== '/auth_login') {
          router.replace({
            path: '/auth_login',
            query: {
              redirect_url: encodeURIComponent(location.href)
            }
          })
          return
        }
        if (res.result != 'true') {
          !no_tips && message.error(res.errMsg || '操作失败');
          reject(res)
        }
        resolve(res.data || {})
      }).catch(error => {
        // 对响应错误做点什么
        let err = {
          code: 999,
          message: '操作失败'
        }
        if (!no_loading) {
          store.dispatch('hideLoading')
        }
        if (!no_tips) {
          message.error(err.errMsg || '操作失败');
        }
        reject(err)
      })
    })
  }
}

export default http