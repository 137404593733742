import View from './index.vue'

export default [
  {
    title: '登录',
    path: '/_mobile',
    redirect: '/auth_login_mobile'
  },{
  title: '登录',
  path: '/auth_login_mobile',
  component: View
}]