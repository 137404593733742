import axios from 'axios'
import file2md5 from 'file2md5'
import collector from '@/assets/collector'

const uploadHttp = (config) => {
  return new Promise((resolve, reject) => {
    axios(config).then(response => {
      const res = response.data || { message: '操作失败' }
      if (res.result == 'true') {
        res.data.name = config.ex_data.name
        resolve(res.data || true)
      } else {
        reject(res)
      }
    }).catch(error => {
      console.log(error)
      reject(error)
    })
  })
}
// 上传到Oss
const Upload2Oss = (param, cb) => {
  let _default = {
    max_size: 50,
    single: true,
    check_type: '',
    oss_type: 1,      // 1-公开 2-加密
    type: 'all'       // image - doc - docs(各种复杂类型的文件)
  }
  let params = Object.assign({}, _default, param)
  switch(params.type) {
    case 'image':
      params.file_type = 'jpg-jpeg-png-bmp'
      break
    case 'video':
      params.file_type = 'avi-wmv-mpeg-mp4-m4v-mov-asf-flv-f4v-rmvb-rm-3gp-vob'
      break
    case 'doc':
      params.file_type = 'txt-doc-docx-wps-xls-xlsx-ppt-pptx-pdf-dwg-dws-dwt-dxf-bak-zip-rar-xmind-xmap-vsd-mpp'
      break
    case 'all':
      params.file_type = 'txt-doc-docx-wps-xls-xlsx-ppt-pptx-pdf-dwg-dws-dwt-dxf-bak-zip-rar-xmind-xmap-vsd-mpp-jpg-jpeg-png-bmp'
      break
  }
  window.Util.selectFile(params, async (files) => {
    if (files.length === 0) {
      return
    }
    let file_data = [] //服务端返回的文件数据 
    let request_config = [] //请求配置
    for(var i = 0, l = files.length; i < l; i++) {
      let _file = files[i]
      if (_file.size > params.max_size*1024*1024) {
        $message.error(`文件大小不能超过${params.max_size}M`)
        continue
      }
      const file = {
        source: _file,
        name: _file.name,
        oss_name: _file.name,   // 保存在oss上的名称(唯一)
        hash: _file.name,
        file_type: _file.file_type
      }
  
      try {
        file.hash = await file2md5(_file)
      } catch (e) {
        console.log(e)
      }
  
      let token = await window.BaseService.getOssToken({
        ...file,
        oss_type: params.oss_type
      })
      if (token.hash) {
        file_data.push({
          id: token.hash.id,
          name: file.name,
          url: token.hash.url,
          preview_url: token.hash.preview_url
        })
      } else {
        const FD = new FormData()
        FD.append('name', `${file.hash}.${file.file_type}`)
        FD.append('key', `${token.dir}${file.hash}.${file.file_type}`)
        FD.append('policy', token.policy)
        FD.append('OSSAccessKeyId', token.accessid)
        FD.append('success_action_status', 200)
        FD.append('callback', token.callback)
        FD.append('signature', token.signature)
        FD.append('file', file.source)
        request_config.push({
          method: 'POST',
          url: token.host,
          headers: {'Content-Type': 'multipart/form-data'},
          data: FD,
          responseType: 'json',
          ex_data: {
            name: file.name
          } 
        })
      }
    }
    if (request_config.length > 0) {
      let [...results] = await collector(...request_config.map(val=>uploadHttp(val)))
      for (var res of results) {
        if (res) {
          file_data.push(res)
        }
      }
    }
    cb(file_data)
  })
}


export { Upload2Oss }